import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Button, 
  IconButton, 
  Skeleton,
  Fade, 
  Grow,
  CircularProgress,
  useTheme,
  alpha,
  Paper,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  SwipeableDrawer, // Added for mobile
  Divider,          // Added for better mobile separation
  Menu,             // Added for mobile filter dropdown
  MenuItem          // Added for mobile filter dropdown
} from '@mui/material';
import { 
  YouTube as YouTubeIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  FilterList as FilterListIcon,
  SportsEsports as SportsEsportsIcon,
  ExpandMore as ExpandMoreIcon  // Added for mobile dropdown
} from '@mui/icons-material';

import { FaTwitch as TwitchIcon } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

// Social media platform configs
const socialPlatforms = {
  youtube: { icon: <YouTubeIcon />, color: '#ff0000', label: 'YouTube' },
  twitch: { icon: <TwitchIcon />, color: '#6441a5', label: 'Twitch' },
  twitter: { icon: <TwitterIcon />, color: '#1da1f2', label: 'Twitter' },
  instagram: { icon: <InstagramIcon />, color: '#e1306c', label: 'Instagram' }
};

// Game types with colors - focused on Rocket League 4Mans content
const contentTypes = [
  { id: 'all', label: 'All Content', color: '#3f51b5' },
  { id: '4mans-gameplay', label: '4Mans Gameplay', color: '#4caf50' },
  { id: 'tutorials', label: 'RL Tutorials', color: '#ff9800' },
  { id: 'tournament', label: 'Tournament Coverage', color: '#f44336' },
  { id: 'analysis', label: 'Game Analysis', color: '#2196f3' },
  { id: 'other', label: 'Other', color: '#f5e618' },
  { id: 'development', label: 'Development', color: '#2c423f' }
];

const ContentCreatorCard = ({ creator, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  
  return (
    <Grow in timeout={500}>
      <Card 
        component={motion.div}
        whileHover={{ 
          y: isMobile ? -5 : -10, // Reduced hover movement on mobile
          boxShadow: '0 20px 30px rgba(0,0,0,0.2)' 
        }}
        transition={{ type: 'spring', stiffness: 300 }}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: { xs: 3, sm: 4 }, // Smaller border radius on mobile
          overflow: 'hidden',
          background: isDarkMode 
            ? alpha(theme.palette.background.paper, 0.8)
            : alpha(theme.palette.background.paper, 0.9),
          backdropFilter: 'blur(10px)',
          border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          boxShadow: `0 10px 30px ${alpha(theme.palette.common.black, 0.1)}`,
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            component="img"
            height={isMobile ? "140" : "160"} // Reduced height on mobile
            image={creator.bannerImage || 'https://via.placeholder.com/400x160/2196f3/ffffff?text=4Mans+Creator'}
            alt={creator.name}
            sx={{ 
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
          <Box 
            sx={{ 
              position: 'absolute', 
              bottom: 0, 
              left: 0, 
              right: 0, 
              height: '100%',
              background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 50%)',
            }} 
          />
          <Box 
            sx={{ 
              position: 'absolute', 
              top: 8, 
              right: 8,
              display: 'flex',
              gap: 0.5, // Reduced gap on mobile
              flexWrap: 'wrap', // Allow wrapping on small screens
              maxWidth: '70%', // Prevent overflow
            }}
          >
            {creator.contentTypes?.map(type => {
              const contentType = contentTypes.find(ct => ct.id === type) || contentTypes[0];
              return (
                <Chip 
                  key={type} 
                  label={contentType.label.split(' ')[0]}
                  size="small"
                  sx={{
                    fontSize: '0.6rem',
                    height: isMobile ? 16 : 20, // Smaller on mobile
                    background: alpha(contentType.color, 0.8),
                    color: '#fff',
                    fontWeight: 'bold',
                    backdropFilter: 'blur(5px)',
                    mb: 0.5, // Add margin for wrapping
                  }}
                />
              );
            })}
          </Box>
          <Box 
            sx={{ 
              position: 'absolute', 
              bottom: isMobile ? -30 : -40, // Smaller offset on mobile
              left: isMobile ? 16 : 20,
              width: isMobile ? 60 : 80, // Smaller profile picture on mobile
              height: isMobile ? 60 : 80,
              borderRadius: '50%',
              overflow: 'hidden',
              border: `${isMobile ? 3 : 4}px solid ${theme.palette.background.paper}`,
              boxShadow: `0 8px 16px ${alpha(theme.palette.common.black, 0.2)}`,
            }}
          >
            <img 
              src={creator.avatarImage || 'https://via.placeholder.com/80/2196f3/ffffff?text=RL'}
              alt={creator.name}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        </Box>
        
        <CardContent 
          sx={{ 
            pt: isMobile ? 3.5 : 5, // Adjusted for smaller profile picture
            pb: isMobile ? 1.5 : 2, 
            px: isMobile ? 1.5 : 2, // Less horizontal padding on mobile
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column' 
          }}
        >
          <Typography 
            variant={isMobile ? "subtitle1" : "h6"} // Smaller heading on mobile
            fontWeight="bold" 
            gutterBottom 
            sx={{ mt: 0.5 }}
            noWrap // Prevent name from taking too many lines
          >
            {creator.name}
          </Typography>
          
          {creator.rank && (
            <Chip
              label={`Rank: ${creator.rank}`}
              size="small"
              sx={{
                alignSelf: 'flex-start',
                mb: 1,
                background: alpha(theme.palette.primary.main, 0.1),
                color: theme.palette.primary.main,
                fontWeight: 600,
                fontSize: '0.7rem',
                height: isMobile ? 20 : 24, // Smaller height on mobile
              }}
            />
          )}
          
          <Typography 
            variant={isMobile ? "caption" : "body2"} // Smaller text on mobile
            color="textSecondary" 
            sx={{ 
              mb: 1.5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2, // Reduced from 3 to 2 for mobile
              WebkitBoxOrient: 'vertical',
              flexGrow: 1,
            }}
          >
            {creator.description || 'Content creator for OCE-4Mans Rocket League'}
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 0.5, mt: 'auto' }}>
            {Object.entries(creator.socialLinks || {}).map(([platform, link]) => {
              if (!link || !socialPlatforms[platform]) return null;
              
              return (
                <Tooltip title={socialPlatforms[platform].label} key={platform}>
                  <IconButton 
                    size={isMobile ? "small" : "medium"}
                    sx={{ 
                      color: 'white',
                      backgroundColor: socialPlatforms[platform].color,
                      padding: isMobile ? '4px' : '8px', // Smaller padding on mobile
                      '&:hover': {
                        backgroundColor: alpha(socialPlatforms[platform].color, 0.8),
                        transform: 'scale(1.1)',
                      },
                      transition: 'all 0.2s ease',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(link, '_blank');
                    }}
                  >
                    {socialPlatforms[platform].icon}
                  </IconButton>
                </Tooltip>
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </Grow>
  );
};

// Mobile version of detail dialog to use a bottom drawer on small screens
const CreatorDetailDialog = ({ open, creator, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';
  
  if (!creator) return null;

  // If mobile, render as a bottom drawer
  if (isMobile) {
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {}}
        disableSwipeToOpen
        PaperProps={{
          sx: {
            maxHeight: '95vh',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            background: isDarkMode 
              ? alpha(theme.palette.background.paper, 0.95)
              : alpha(theme.palette.background.paper, 0.98),
            backdropFilter: 'blur(10px)',
            overflow: 'hidden',
          }
        }}
      >
        <Box sx={{ 
          width: '100%', 
          height: 6, 
          borderRadius: 3,
          backgroundColor: alpha(theme.palette.common.black, 0.1),
          mx: 'auto',
          my: 1,
          maxWidth: 40,
        }} />
        
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            component="img"
            height="180" // Reduced height for mobile
            image={creator.bannerImage || 'https://via.placeholder.com/1200x240/2196f3/ffffff?text=OCE-4Mans+Creator'}
            alt={creator.name}
            sx={{ objectFit: 'cover' }}
          />
          <Box 
            sx={{ 
              position: 'absolute', 
              bottom: 0, 
              left: 0, 
              right: 0, 
              height: '100%',
              background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%)',
            }} 
          />
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: alpha(theme.palette.common.black, 0.4),
              backdropFilter: 'blur(5px)',
              '&:hover': {
                bgcolor: alpha(theme.palette.common.black, 0.6),
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              p: 2,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              color: 'white',
            }}
          >
            <Box 
              sx={{ 
                width: 60, // Smaller for mobile
                height: 60,
                borderRadius: '50%',
                overflow: 'hidden',
                border: `3px solid ${theme.palette.background.paper}`,
                boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.2)}`,
                flexShrink: 0,
              }}
            >
              <img 
                src={creator.avatarImage || 'https://via.placeholder.com/100/2196f3/ffffff?text=RL'}
                alt={creator.name}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Box sx={{ maxWidth: 'calc(100% - 80px)' }}>
              <Typography variant="h6" fontWeight="bold" sx={{ textShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>
                {creator.name}
              </Typography>
              
              {creator.rank && (
                <Chip 
                  label={`Rank: ${creator.rank}`}
                  size="small"
                  sx={{
                    height: 20,
                    background: alpha(theme.palette.primary.main, 0.8),
                    color: '#fff',
                    fontWeight: 'bold',
                    backdropFilter: 'blur(5px)',
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        
        <DialogContent sx={{ pt: 2, pb: 4, px: 2 }}>
          {/* Content types in a scrollable row */}
          <Box 
            sx={{ 
              display: 'flex', 
              flexWrap: 'nowrap', 
              overflowX: 'auto', 
              gap: 0.5, 
              pb: 1,
              mb: 2,
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              scrollbarWidth: 'none',
              '-ms-overflow-style': 'none',
            }}
          >
            {creator.contentTypes?.map(type => {
              const contentType = contentTypes.find(ct => ct.id === type) || contentTypes[0];
              return (
                <Chip 
                  key={type} 
                  label={contentType.label}
                  size="small"
                  sx={{
                    background: alpha(contentType.color, 0.8),
                    color: '#fff',
                    fontWeight: 'bold',
                    backdropFilter: 'blur(5px)',
                    flexShrink: 0,
                  }}
                />
              );
            })}
          </Box>
          
          <Typography variant="subtitle1" fontWeight="600" gutterBottom>
            About
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            {creator.description || 'Content creator for OCE-4Mans Rocket League community'}
          </Typography>
          
          <Divider sx={{ my: 2 }} />
          
          {/* Social Links */}
          <Typography variant="subtitle1" fontWeight="600" gutterBottom>
            Connect
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {Object.entries(creator.socialLinks || {}).some(([_, link]) => link) ? (
              Object.entries(creator.socialLinks || {}).map(([platform, link]) => {
                if (!link || !socialPlatforms[platform]) return null;
                
                return (
                  <Button
                    key={platform}
                    variant="outlined"
                    size="small"
                    startIcon={socialPlatforms[platform].icon}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      justifyContent: 'flex-start',
                      borderRadius: 2,
                      borderColor: alpha(socialPlatforms[platform].color, 0.5),
                      color: socialPlatforms[platform].color,
                      '&:hover': {
                        borderColor: socialPlatforms[platform].color,
                        backgroundColor: alpha(socialPlatforms[platform].color, 0.1),
                      },
                      textTransform: 'none',
                      flexGrow: 1,
                      minWidth: '48%', // Two buttons per row
                    }}
                  >
                    {socialPlatforms[platform].label}
                  </Button>
                );
              })
            ) : (
              <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', py: 1, width: '100%' }}>
                No social links available
              </Typography>
            )}
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          {creator.featuredContent && (
            <>
              <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                Featured 4Mans Content
              </Typography>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 1, 
                  background: alpha(theme.palette.background.default, 0.5), 
                  borderRadius: 2,
                  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                  mb: 2,
                }}
              >
                {creator.featuredContent.type === 'youtube' ? (
                  <Box 
                    sx={{ 
                      position: 'relative',
                      paddingBottom: '56.25%', // 16:9 aspect ratio
                      height: 0,
                      overflow: 'hidden',
                      borderRadius: 1,
                    }}
                  >
                    <iframe
                      src={`https://www.youtube.com/embed/${creator.featuredContent.videoId}`}
                      title={creator.featuredContent.title || 'Featured 4Mans video'}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 0,
                      }}
                      allowFullScreen
                    />
                  </Box>
                ) : creator.featuredContent.type === 'twitch' ? (
                  <Box 
                    sx={{ 
                      position: 'relative',
                      paddingBottom: '56.25%', // 16:9 aspect ratio
                      height: 0,
                      overflow: 'hidden',
                      borderRadius: 1,
                    }}
                  >
                    <iframe
                      src={`https://player.twitch.tv/?channel=${creator.featuredContent.channelName}&parent=${window.location.hostname}`}
                      title={creator.featuredContent.title || 'Featured 4Mans stream'}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 0,
                      }}
                      allowFullScreen
                    />
                  </Box>
                ) : (
                  <Typography variant="body2">
                    {creator.featuredContent.description || 'Check out my OCE-4Mans content!'}
                  </Typography>
                )}
              </Paper>
              
              <Divider sx={{ my: 2 }} />
            </>
          )}
          
          {creator.achievements && (
            <>
              <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                OCE-4Mans Achievements
              </Typography>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 1.5, 
                  background: alpha(theme.palette.background.default, 0.5), 
                  borderRadius: 2,
                  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                  mb: 2,
                }}
              >
                <Typography variant="body2" component="div">
                  <div dangerouslySetInnerHTML={{ __html: creator.achievements }} />
                </Typography>
              </Paper>
              
              <Divider sx={{ my: 2 }} />
            </>
          )}
          
          {creator.schedule && (
            <>
              <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                4Mans Stream Schedule
              </Typography>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 1.5, 
                  background: alpha(theme.palette.background.default, 0.5), 
                  borderRadius: 2,
                  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                }}
              >
                <Typography variant="body2" component="div">
                  <div dangerouslySetInnerHTML={{ __html: creator.schedule }} />
                </Typography>
              </Paper>
            </>
          )}
        </DialogContent>
      </SwipeableDrawer>
    );
  }
  
  // Desktop/tablet version
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullScreen={isTablet}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, sm: 4 },
          background: isDarkMode 
            ? alpha(theme.palette.background.paper, 0.9)
            : alpha(theme.palette.background.paper, 0.95),
          backdropFilter: 'blur(10px)',
          overflow: 'hidden',
        }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height="240"
          image={creator.bannerImage || 'https://via.placeholder.com/1200x240/2196f3/ffffff?text=OCE-4Mans+Creator'}
          alt={creator.name}
          sx={{ objectFit: 'cover' }}
        />
        <Box 
          sx={{ 
            position: 'absolute', 
            bottom: 0, 
            left: 0, 
            right: 0, 
            height: '100%',
            background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%)',
          }} 
        />
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            bgcolor: alpha(theme.palette.common.black, 0.4),
            backdropFilter: 'blur(5px)',
            '&:hover': {
              bgcolor: alpha(theme.palette.common.black, 0.6),
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            p: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            gap: 3,
            color: 'white',
          }}
        >
          <Box 
            sx={{ 
              width: { xs: 80, sm: 100 },
              height: { xs: 80, sm: 100 },
              borderRadius: '50%',
              overflow: 'hidden',
              border: `4px solid ${theme.palette.background.paper}`,
              boxShadow: `0 8px 16px ${alpha(theme.palette.common.black, 0.2)}`,
            }}
          >
            <img 
              src={creator.avatarImage || 'https://via.placeholder.com/100/2196f3/ffffff?text=RL'}
              alt={creator.name}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          <Box sx={{ maxWidth: 'calc(100% - 120px)' }}>
            <Typography variant="h4" fontWeight="bold" sx={{ textShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>
              {creator.name}
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
              {creator.rank && (
                <Chip 
                  label={`Rank: ${creator.rank}`}
                  size="small"
                  sx={{
                    background: alpha(theme.palette.primary.main, 0.8),
                    color: '#fff',
                    fontWeight: 'bold',
                    backdropFilter: 'blur(5px)',
                  }}
                />
              )}
              
              {creator.contentTypes?.map(type => {
                const contentType = contentTypes.find(ct => ct.id === type) || contentTypes[0];
                return (
                  <Chip 
                    key={type} 
                    label={contentType.label}
                    size="small"
                    sx={{
                      background: alpha(contentType.color, 0.8),
                      color: '#fff',
                      fontWeight: 'bold',
                      backdropFilter: 'blur(5px)',
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
      
      <DialogContent sx={{ pt: 3, pb: 4, px: { xs: 2, sm: 4 } }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" fontWeight="600" gutterBottom>
              About
            </Typography>
            <Typography paragraph color="textSecondary">
              {creator.description || 'Content creator for OCE-4Mans Rocket League community'}
            </Typography>
            
            {creator.featuredContent && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" fontWeight="600" gutterBottom>
                  Featured 4Mans Content
                </Typography>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 2, 
                    background: alpha(theme.palette.background.default, 0.5), 
                    borderRadius: 3,
                    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                  }}
                >
                  {creator.featuredContent.type === 'youtube' ? (
                    <Box 
                      sx={{ 
                        position: 'relative',
                        paddingBottom: '56.25%', // 16:9 aspect ratio
                        height: 0,
                        overflow: 'hidden',
                        borderRadius: 2,
                      }}
                    >
                      <iframe
                        src={`https://www.youtube.com/embed/${creator.featuredContent.videoId}`}
                        title={creator.featuredContent.title || 'Featured 4Mans video'}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          border: 0,
                        }}
                        allowFullScreen
                      />
                    </Box>
                  ) : creator.featuredContent.type === 'twitch' ? (
                    <Box 
                      sx={{ 
                        position: 'relative',
                        paddingBottom: '56.25%', // 16:9 aspect ratio
                        height: 0,
                        overflow: 'hidden',
                        borderRadius: 2,
                      }}
                    >
                      <iframe
                        src={`https://player.twitch.tv/?channel=${creator.featuredContent.channelName}&parent=${window.location.hostname}`}
                        title={creator.featuredContent.title || 'Featured 4Mans stream'}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          border: 0,
                        }}
                        allowFullScreen
                      />
                    </Box>
                  ) : (
                    <Typography variant="body2">
                      {creator.featuredContent.description || 'Check out my OCE-4Mans content!'}
                    </Typography>
                  )}
                </Paper>
              </Box>
            )}
            
            {creator.achievements && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" fontWeight="600" gutterBottom>
                  OCE-4Mans Achievements
                </Typography>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 2, 
                    background: alpha(theme.palette.background.default, 0.5), 
                    borderRadius: 3,
                    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                  }}
                >
                  <Typography variant="body2" component="div">
                    <div dangerouslySetInnerHTML={{ __html: creator.achievements }} />
                  </Typography>
                </Paper>
              </Box>
            )}
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight="600" gutterBottom>
              Connect
            </Typography>
            
            <Paper 
              elevation={0} 
              sx={{ 
                p: 2, 
                background: alpha(theme.palette.background.default, 0.5), 
                borderRadius: 3,
                border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              }}
            >
              {Object.entries(creator.socialLinks || {}).some(([_, link]) => link) ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {Object.entries(creator.socialLinks || {}).map(([platform, link]) => {
                    if (!link || !socialPlatforms[platform]) return null;
                    
                    return (
                      <Button
                        key={platform}
                        variant="outlined"
                        fullWidth
                        startIcon={socialPlatforms[platform].icon}
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          justifyContent: 'flex-start',
                          borderRadius: 2,
                          borderColor: alpha(socialPlatforms[platform].color, 0.5),
                          color: socialPlatforms[platform].color,
                          '&:hover': {
                            borderColor: socialPlatforms[platform].color,
                            backgroundColor: alpha(socialPlatforms[platform].color, 0.1),
                          },
                          textTransform: 'none',
                        }}
                      >
                        {socialPlatforms[platform].label}
                      </Button>
                    );
                  })}
                </Box>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', py: 2 }}>
                  No social links available
                </Typography>
              )}
            </Paper>
            
            {creator.schedule && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" fontWeight="600" gutterBottom>
                  4Mans Stream Schedule
                </Typography>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 2, 
                    background: alpha(theme.palette.background.default, 0.5), 
                    borderRadius: 3,
                    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                  }}
                >
                  <Typography variant="body2" component="div">
                    <div dangerouslySetInnerHTML={{ __html: creator.schedule }} />
                  </Typography>
                </Paper>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const ContentCreatorsPage = () => {
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState('all');
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null); // For mobile filter dropdown
  
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchCreators = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${apiUrl}/api/content-creators`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch content creators');
        }
        
        const data = await response.json();
        setCreators(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching content creators:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchCreators();
  }, []);

  const handleTypeChange = (event, newValue) => {
    setSelectedType(newValue);
  };
  
  const handleCreatorClick = (creator) => {
    setSelectedCreator(creator);
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  
  // Filter menu handlers for mobile
  const handleOpenFilterMenu = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };
  
  const handleCloseFilterMenu = () => {
    setFilterMenuAnchor(null);
  };
  
  const handleFilterSelect = (typeId) => {
    setSelectedType(typeId);
    handleCloseFilterMenu();
  };
  
  // Filter creators by content type
  const filteredCreators = creators.filter(creator => 
    selectedType === 'all' || 
    (creator.contentTypes && creator.contentTypes.includes(selectedType))
  );
  
  // Empty state content
  const emptyStateContent = (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        textAlign: 'center',
        py: isMobile ? 6 : 10,
        px: isMobile ? 2 : 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: isMobile ? 2 : 3,
      }}
    >
      <SportsEsportsIcon 
        sx={{ 
          fontSize: isMobile ? 60 : 80, 
          color: alpha(theme.palette.primary.main, 0.7),
          filter: `drop-shadow(0 4px 8px ${alpha(theme.palette.primary.main, 0.4)})`,
        }} 
      />
      
      <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold" color="primary">
        Content Creators Coming Soon
      </Typography>
      
      <Typography variant={isMobile ? "body2" : "body1"} sx={{ maxWidth: 600, mb: 2, color: theme.palette.text.secondary }}>
        We're in the process of partnering with amazing Rocket League content creators from our OCE-4Mans community. 
        This page will soon showcase streamers, YouTubers, and other talented individuals who create content for our 4Mans matches and tournaments.
      </Typography>
      
      <Button 
        variant="contained" 
        color="primary"
        href="/modmail"
        sx={{ 
          borderRadius: 8,
          px: isMobile ? 3 : 4,
          py: isMobile ? 1 : 1.5,
          textTransform: 'none',
          boxShadow: `0 4px 14px ${alpha(theme.palette.primary.main, 0.4)}`,
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.6)}`,
          }
        }}
      >
        Apply to be a Featured Creator
      </Button>
    </Box>
  );
  
  // Error state content
  const errorStateContent = (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        textAlign: 'center',
        py: isMobile ? 6 : 10,
        px: isMobile ? 2 : 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: isMobile ? 2 : 3,
      }}
    >
      <ErrorIcon 
        sx={{ 
          fontSize: isMobile ? 60 : 80, 
          color: theme.palette.error.main,
          filter: `drop-shadow(0 4px 8px ${alpha(theme.palette.error.main, 0.4)})`,
        }} 
      />
      
      <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold" color="error">
        Oops! Something went wrong
      </Typography>
      
      <Typography variant={isMobile ? "body2" : "body1"} sx={{ maxWidth: 600, mb: 2, color: theme.palette.text.secondary }}>
        We're having trouble loading our OCE-4Mans content creators right now. Please try again later or contact our support team for assistance.
      </Typography>
      
      <Button 
        variant="contained" 
        color="primary"
        onClick={() => window.location.reload()}
        sx={{ 
          borderRadius: 8,
          px: isMobile ? 3 : 4,
          py: isMobile ? 1 : 1.5,
          textTransform: 'none',
          boxShadow: `0 4px 14px ${alpha(theme.palette.primary.main, 0.4)}`,
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.6)}`,
          }
        }}
      >
        Try Again
      </Button>
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{ my: isMobile ? 3 : 5, px: isMobile ? 2 : 3 }}>
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        sx={{ 
          textAlign: 'center',
          mb: isMobile ? 3 : 5
        }}
      >
        <Typography 
          variant={isMobile ? "h3" : "h2"} 
          component="h1" 
          fontWeight="bold"
          sx={{ 
            mb: isMobile ? 1 : 2,
            fontSize: isMobile ? '2rem' : '3rem',
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 2px 10px rgba(0,0,0,0.1)',
          }}
        >
          OCE-4Mans Content Creators
        </Typography>
        
        <Typography 
          variant={isMobile ? "subtitle1" : "h6"} 
          color="textSecondary" 
          sx={{ 
            maxWidth: 700, 
            mx: 'auto',
            px: isMobile ? 1 : 0
          }}
        >
          Discover talented creators making amazing content for our Rocket League 4Mans community
        </Typography>
      </Box>
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: isMobile ? 6 : 10 }}>
          <CircularProgress size={isMobile ? 40 : 60} />
        </Box>
      ) : error || creators.length === 0 ? (
        <Paper
          elevation={3}
          sx={{
            borderRadius: isMobile ? 3 : 4,
            overflow: 'hidden',
            background: isDarkMode 
              ? alpha(theme.palette.background.paper, 0.8)
              : alpha(theme.palette.background.paper, 0.9),
            backdropFilter: 'blur(10px)',
            boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
          }}
        >
          {error ? errorStateContent : emptyStateContent}
        </Paper>
      ) : (
        <>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            sx={{ mb: isMobile ? 3 : 4 }}
          >
            {/* Desktop filter */}
            {!isMobile && (
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  borderRadius: 8,
                  background: isDarkMode 
                    ? alpha(theme.palette.background.paper, 0.8)
                    : alpha(theme.palette.background.paper, 0.9),
                  backdropFilter: 'blur(10px)',
                  boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, gap: 1 }}>
                  <FilterListIcon />
                  <Typography fontWeight="medium">Filter by content type:</Typography>
                </Box>
                
                <Tabs
                  value={selectedType}
                  onChange={handleTypeChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      minWidth: 100,
                      fontWeight: 500,
                      fontSize: '0.9rem',
                      color: theme.palette.text.secondary,
                      '&.Mui-selected': {
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                      },
                    },
                    '& .MuiTabs-indicator': {
                      borderRadius: 4,
                      height: 3,
                    },
                  }}
                >
                  <Tab value="all" label="All Content" />
                  <Tab value="4mans-gameplay" label="4Mans Gameplay" />
                  <Tab value="tutorials" label="RL Tutorials" />
                  <Tab value="tournament" label="Tournament Coverage" />
                  <Tab value="other" label="Other" />
                  <Tab value="development" label="Development" />
                </Tabs>
              </Paper>
            )}
            
            {/* Mobile filter dropdown */}
            {isMobile && (
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 4,
                  background: isDarkMode 
                    ? alpha(theme.palette.background.paper, 0.8)
                    : alpha(theme.palette.background.paper, 0.9),
                  backdropFilter: 'blur(10px)',
                  boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
                }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleOpenFilterMenu}
                  endIcon={<ExpandMoreIcon />}
                  startIcon={<FilterListIcon />}
                  sx={{
                    justifyContent: 'space-between',
                    textTransform: 'none',
                    borderRadius: 2,
                    p: 1,
                    fontWeight: 500,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      {contentTypes.find(type => type.id === selectedType)?.label || 'All Content'}
                    </Typography>
                  </Box>
                </Button>
                
                <Menu
                  anchorEl={filterMenuAnchor}
                  open={Boolean(filterMenuAnchor)}
                  onClose={handleCloseFilterMenu}
                  PaperProps={{
                    sx: {
                      mt: 1,
                      width: filterMenuAnchor ? filterMenuAnchor.clientWidth : 'auto',
                      borderRadius: 2,
                      boxShadow: `0 8px 20px ${alpha(theme.palette.common.black, 0.15)}`,
                    }
                  }}
                >
                  {contentTypes.map((type) => (
                    <MenuItem
                      key={type.id}
                      selected={selectedType === type.id}
                      onClick={() => handleFilterSelect(type.id)}
                      sx={{
                        minHeight: 'auto',
                        py: 1.5,
                        px: 2,
                        '&.Mui-selected': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.2),
                          }
                        }
                      }}
                    >
                      <Typography variant="body2">
                        {type.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Paper>
            )}
          </Box>

          <AnimatePresence>
            {filteredCreators.length > 0 ? (
              <Grid container spacing={isMobile ? 2 : 3}>
                {filteredCreators.map((creator, index) => (
                  <Grid 
                    item 
                    xs={12} 
                    sm={6} 
                    md={4} 
                    key={creator.id}
                    component={motion.div}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 + 0.2 }}
                  >
                    <ContentCreatorCard 
                      creator={creator} 
                      onClick={() => handleCreatorClick(creator)}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Paper
                elevation={3}
                sx={{
                  p: isMobile ? 3 : 5,
                  borderRadius: 4,
                  textAlign: 'center',
                  background: isDarkMode 
                    ? alpha(theme.palette.background.paper, 0.8)
                    : alpha(theme.palette.background.paper, 0.9),
                  backdropFilter: 'blur(10px)',
                }}
              >
                <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
                  No creators found for this category
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Try selecting a different content type or check back later
                </Typography>
              </Paper>
            )}
          </AnimatePresence>
        </>
      )}
      
      <CreatorDetailDialog 
        open={dialogOpen} 
        creator={selectedCreator} 
        onClose={handleCloseDialog} 
      />
    </Container>
  );
};

export default ContentCreatorsPage;