import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Box, 
  CircularProgress, 
  Paper,
  useTheme,
  alpha,
  Fade,
  Grow,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { motion } from 'framer-motion';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

const Authorised = () => {
    const [isAuthorized, setIsAuthorized] = useState(null);
    const [fadeIn, setFadeIn] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    // Determine if we're in dark mode
    const isDarkMode = theme.palette.mode === 'dark';
    
    // Custom colors based on theme
    const primaryColor = theme.palette.primary.main;
    const successColor = theme.palette.success.main || '#43b581';
    const errorColor = theme.palette.error.main || '#f04747';
    const bgGradient = isDarkMode 
        ? `linear-gradient(135deg, ${alpha(primaryColor, 0.7)} 0%, ${alpha(primaryColor, 0.4)} 100%)`
        : `linear-gradient(135deg, ${primaryColor} 0%, ${alpha(primaryColor, 0.8)} 100%)`;
    
    // Card background based on theme
    const cardBgColor = isDarkMode 
        ? alpha(theme.palette.background.paper, 0.8)
        : 'rgba(255, 255, 255, 0.9)';
    
    // Text color based on theme
    const textColor = isDarkMode ? '#ffffff' : theme.palette.text.primary;

    useEffect(() => {
        const checkAuthorization = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/auth/discord/status`, {
                    credentials: 'include',
                    method: 'GET'
                });
                const data = await response.json();
                setIsAuthorized(data.logged_in);
                
                // If authorized, set a timeout to redirect after showing success message
                if (data.logged_in) {
                    setTimeout(() => {
                        navigate('/');
                    }, 5000); 
                }
            } catch (error) {
                console.error('Error checking authorization status:', error);
                setIsAuthorized(false);
            } finally {
                // Trigger fade-in animation once data is loaded
                setTimeout(() => setFadeIn(true), 100);
            }
        };

        checkAuthorization();
    }, [navigate]);

    const handleGoBack = () => {
        navigate('/');
    };

    // Animated background patterns
    const AnimatedBackground = () => (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'hidden',
                zIndex: 0,
            }}
        >
            {[...Array(6)].map((_, i) => (
                <Box
                    component={motion.div}
                    key={i}
                    initial={{ 
                        opacity: 0.4, 
                        x: Math.random() * 100 - 50,
                        y: Math.random() * 100 - 50,
                    }}
                    animate={{ 
                        opacity: [0.4, 0.2, 0.4], 
                        x: [null, Math.random() * 50 - 25],
                        y: [null, Math.random() * 50 - 25],
                    }}
                    transition={{ 
                        duration: 15 + Math.random() * 10,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                    sx={{
                        position: 'absolute',
                        width: 150 + (i * 50),
                        height: 150 + (i * 50),
                        borderRadius: '50%',
                        background: alpha(primaryColor, 0.1),
                        filter: 'blur(50px)',
                        top: `${Math.random() * 100}%`,
                        left: `${Math.random() * 100}%`,
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            ))}
        </Box>
    );

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: bgGradient,
                padding: 2,
                position: 'relative',
                overflow: 'hidden',
                color: textColor, // Ensure all text inherits this color by default
            }}
        >
            <AnimatedBackground />
            
            <Fade in={fadeIn} timeout={800}>
                <Paper
                    elevation={10}
                    sx={{
                        position: 'relative', // To appear above the animated background
                        zIndex: 1,
                        padding: { xs: 3, sm: 4 },
                        borderRadius: 3,
                        maxWidth: 500,
                        width: '100%',
                        textAlign: 'center',
                        background: cardBgColor,
                        backdropFilter: 'blur(10px)',
                        boxShadow: isDarkMode 
                            ? '0 8px 32px rgba(0, 0, 0, 0.3)' 
                            : '0 8px 32px rgba(0, 0, 0, 0.1)',
                        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            boxShadow: '0 12px 40px rgba(0, 0, 0, 0.2)',
                            transform: 'translateY(-5px)',
                        },
                    }}
                >
                    {isAuthorized === null ? (
                        <Fade in timeout={500}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                                <CircularProgress 
                                    size={60} 
                                    sx={{ 
                                        color: primaryColor,
                                        filter: `drop-shadow(0 0 10px ${alpha(primaryColor, 0.5)})`,
                                    }} 
                                />
                                <Typography variant="h5" fontWeight="medium" color="inherit">
                                    Verifying your authorization...
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ opacity: 0.8 }}>
                                    Please wait while we complete the process
                                </Typography>
                            </Box>
                        </Fade>
                    ) : isAuthorized ? (
                        <Grow in timeout={800}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                                <Box 
                                    component={motion.div}
                                    animate={{ 
                                        scale: [1, 1.1, 1],
                                    }}
                                    transition={{ 
                                        duration: 2,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 100,
                                        height: 100,
                                        borderRadius: '50%',
                                        background: alpha(successColor, 0.1),
                                        boxShadow: `0 0 20px ${alpha(successColor, 0.3)}`,
                                    }}
                                >
                                    <CheckCircleOutlineIcon 
                                        sx={{ 
                                            fontSize: 70, 
                                            color: successColor,
                                            filter: `drop-shadow(0 0 10px ${alpha(successColor, 0.5)})`,
                                        }} 
                                    />
                                </Box>
                                <Typography 
                                    variant="h4" 
                                    fontWeight="bold" 
                                    sx={{ 
                                        color: successColor,
                                        textShadow: `0 2px 10px ${alpha(successColor, 0.3)}`,
                                    }}
                                >
                                    Success!
                                </Typography>
                                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: 'inherit' }}>
                                    You've been successfully authorized with Discord.
                                    You'll be redirected in a few seconds...
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={handleGoBack}
                                    sx={{
                                        mt: 2,
                                        bgcolor: primaryColor,
                                        color: '#ffffff',
                                        '&:hover': { 
                                            bgcolor: theme.palette.primary.dark,
                                            transform: 'translateY(-2px)',
                                        },
                                        borderRadius: 2,
                                        textTransform: 'none',
                                        padding: '10px 20px',
                                        fontSize: '1rem',
                                        boxShadow: `0 4px 14px ${alpha(primaryColor, 0.4)}`,
                                        transition: 'all 0.3s ease',
                                    }}
                                >
                                    Return to Dashboard
                                </Button>
                                
                                {/* Countdown indicator */}
                                <Box 
                                    component={motion.div}
                                    initial={{ pathLength: 0 }}
                                    animate={{ pathLength: 1 }}
                                    transition={{ duration: 5, ease: "linear" }}
                                    sx={{ 
                                        width: 40, 
                                        height: 40, 
                                        position: 'relative',
                                        mt: 1,
                                    }}
                                >
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                        }}
                                    >
                                        <CircularProgress
                                            variant="indeterminate"
                                            size={40}
                                            thickness={3}
                                            sx={{ 
                                                color: primaryColor,
                                                animationDuration: '5s',
                                            }}
                                        />
                                    </motion.div>
                                </Box>
                            </Box>
                        </Grow>
                    ) : (
                        <Grow in timeout={800}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                                <Box 
                                    component={motion.div}
                                    animate={{ 
                                        rotate: [0, 5, 0, -5, 0],
                                    }}
                                    transition={{ 
                                        duration: 2,
                                        repeat: 2,
                                    }}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 100,
                                        height: 100,
                                        borderRadius: '50%',
                                        background: alpha(errorColor, 0.1),
                                        boxShadow: `0 0 20px ${alpha(errorColor, 0.3)}`,
                                    }}
                                >
                                    <ErrorOutlineIcon 
                                        sx={{ 
                                            fontSize: 70, 
                                            color: errorColor,
                                            filter: `drop-shadow(0 0 10px ${alpha(errorColor, 0.5)})`,
                                        }} 
                                    />
                                </Box>
                                <Typography 
                                    variant="h4" 
                                    fontWeight="bold" 
                                    sx={{ 
                                        color: errorColor,
                                        textShadow: `0 2px 10px ${alpha(errorColor, 0.3)}`,
                                    }}
                                >
                                    Authorization Failed
                                </Typography>
                                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: 'inherit' }}>
                                    We couldn't authorize your Discord account.
                                    Please try again or contact support for assistance.
                                </Typography>
                                <Box sx={{ 
                                    display: 'flex', 
                                    gap: 2,
                                    flexDirection: isMobile ? 'column' : 'row',
                                    width: isMobile ? '100%' : 'auto'
                                }}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        fullWidth={isMobile}
                                        onClick={handleGoBack}
                                        sx={{
                                            bgcolor: primaryColor,
                                            color: '#ffffff',
                                            '&:hover': { 
                                                bgcolor: theme.palette.primary.dark,
                                                transform: 'translateY(-2px)',
                                            },
                                            borderRadius: 2,
                                            textTransform: 'none',
                                            padding: '10px 20px',
                                            fontSize: '1rem',
                                            boxShadow: `0 4px 14px ${alpha(primaryColor, 0.4)}`,
                                            transition: 'all 0.3s ease',
                                        }}
                                    >
                                        Try Again
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        fullWidth={isMobile}
                                        onClick={() => navigate('/modmail')}
                                        sx={{
                                            color: primaryColor,
                                            borderColor: primaryColor,
                                            '&:hover': { 
                                                borderColor: theme.palette.primary.dark, 
                                                bgcolor: alpha(primaryColor, 0.05),
                                                transform: 'translateY(-2px)',
                                            },
                                            borderRadius: 2,
                                            textTransform: 'none',
                                            padding: '10px 20px',
                                            fontSize: '1rem',
                                            transition: 'all 0.3s ease',
                                        }}
                                    >
                                        Contact Support
                                    </Button>
                                </Box>
                            </Box>
                        </Grow>
                    )}
                </Paper>
            </Fade>
        </Box>
    );
};

export default Authorised;