// ServerError.jsx - 500 Page with Dark Mode Support
import React from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import HandymanIcon from '@mui/icons-material/Handyman';
import { useDarkMode } from '../components/DarkModeContext';

// makeStyles with a function that receives the theme and darkMode
const useStyles = (darkMode) => makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
    },
    paper: {
        padding: theme.spacing(6),
        borderRadius: 16,
        boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
        maxWidth: 500,
        width: '100%',
        background: darkMode
            ? 'linear-gradient(145deg, #2d2d2d, #1e1e1e)'
            : 'linear-gradient(145deg, #ffffff, #f5f5f5)',
    },
    icon: {
        fontSize: '4.5rem',
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
        animation: '$rotate 10s linear infinite',
    },
    title: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
        background: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.error.main})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    message: {
        marginBottom: theme.spacing(3),
        color: darkMode ? '#e0e0e0' : theme.palette.text.secondary,
    },
    button: {
        borderRadius: 12,
        textTransform: 'none',
        fontWeight: 600,
        boxShadow: '0 4px 14px 0 rgba(255, 82, 82, 0.39)',
        padding: theme.spacing(1, 3),
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 20px rgba(255, 82, 82, 0.23)',
        },
    },
    '@keyframes rotate': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}));

const ServerError = () => {
    const { darkMode } = useDarkMode();
    const classes = useStyles(darkMode)();
    const navigate = useNavigate();
    const location = useLocation();

    const handleRetry = () => {
        if (location.pathname === '/500') {
            // Navigate to home if we're at the /500 page
            navigate('/');
        } else {
            // Otherwise, just reload the current page
            window.location.reload();
        }
    };

    return (
        <Container className={classes.root}>
            <Paper
                elevation={0}
                className={classes.paper}
                style={{ color: darkMode ? '#ffffff' : 'inherit' }}
            >
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <HandymanIcon className={classes.icon} />

                    <Typography variant="h4" className={classes.title}>
                        500 - We're On It!
                    </Typography>

                    <Typography variant="body1" className={classes.message}>
                        Our digital hamsters seem to have taken a coffee break. We're fixing things behind the scenes and should be back up shortly!
                    </Typography>

                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleRetry}
                        disableElevation
                    >
                        {location.pathname === '/500' ? 'Go to Homepage' : 'Let\'s Try Again'}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default ServerError;