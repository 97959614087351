import { useState } from "react";
import axios from "axios";
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Alert, 
  CircularProgress, 
  Paper,
  Fade,
  Grow,
  Divider,
  Container
} from "@mui/material";
import useAuth from "./hooks/useAuth";

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

const ModMail = ({ user }) => {
    const { loggedIn, userData, setLoggedIn, setUserData, isLoading } = useAuth();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async () => {
        if (!message.trim()) {
            setError("Appeal message cannot be empty.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${apiUrl}/api/modmail`, { message });
            if (response.status === 200) {
                setSuccess(true);
                setMessage("");
                // Auto-hide success message after 5 seconds
                setTimeout(() => setSuccess(false), 5000);
            }
        } catch (err) {
            setError(`Failed to submit mod-mail. ${err.response?.data?.message || 'Please try again later.'}`);
        } finally {
            setLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Container 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    minHeight: '70vh'
                }}
            >
                <CircularProgress size={60} />
            </Container>
        );
    }

    if (!loggedIn) {
        return (
            <Container 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    minHeight: '70vh'
                }}
            >
                <Paper 
                    elevation={3} 
                    sx={{ 
                        p: 4, 
                        textAlign: 'center',
                        maxWidth: 500,
                        width: '100%'
                    }}
                >
                    <Typography variant="h5" color="error" gutterBottom>
                        Authentication Required
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        You must be logged in to submit modmail to our staff team.
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary"
                        href={`${apiUrl}/api/auth/discord/login`}
                    >
                        Login with Discord
                    </Button>
                </Paper>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" sx={{ py: 4, minHeight: '80vh' }}>
            <Fade in={true} timeout={800}>
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, borderRadius: 2 }}>
                    <Typography variant="h4" sx={{ textAlign: "center", mb: 1, fontWeight: 'bold' }}>
                        Contact Staff Team
                    </Typography>
                    
                    <Typography variant="body1" sx={{ textAlign: "center", mb: 4, color: 'text.secondary' }}>
                        Submit a message to our moderation team. We'll get back to you as soon as possible.
                    </Typography>

                    <Divider sx={{ mb: 4 }} />

                    {/* Alert messages with animations */}
                    <Box sx={{ mb: 3 }}>
                        <Grow in={success} timeout={600} unmountOnExit>
                            <Alert 
                                severity="success" 
                                variant="filled"
                                sx={{ mb: 2 }}
                            >
                                Your message has been submitted successfully. Our staff will review it soon.
                            </Alert>
                        </Grow>
                        
                        <Grow in={!!error} timeout={600} unmountOnExit>
                            <Alert 
                                severity="error"
                                variant="filled"
                                sx={{ mb: 2 }}
                            >
                                {error}
                            </Alert>
                        </Grow>
                    </Box>

                    {userData && (
                        <Box sx={{ mb: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1, fontWeight: 'medium' }}>
                                Submitting as: <strong>{userData.username || userData.profileName}</strong>
                            </Typography>
                            {userData.discordId && (
                                <Typography variant="body2" color="text.secondary">
                                    Discord ID: {userData.discordId}
                                </Typography>
                            )}
                        </Box>
                    )}

                    <TextField
                        label="Your Message"
                        multiline
                        rows={6}
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        sx={{ mb: 3 }}
                        variant="outlined"
                        placeholder="Describe your issue or request in detail. Please be as specific as possible to help us assist you better."
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                        disabled={loading}
                        size="large"
                        sx={{ 
                            py: 1.5,
                            position: 'relative',
                            overflow: 'hidden',
                            '&::after': loading ? {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(255, 255, 255, 0.1)',
                                animation: 'pulse 1.5s infinite'
                            } : {}
                        }}
                    >
                        {loading ? (
                            <>
                                <CircularProgress 
                                    size={24} 
                                    color="inherit" 
                                    sx={{ mr: 1 }} 
                                /> 
                                Sending...
                            </>
                        ) : "Submit Message"}
                    </Button>
                </Paper>
            </Fade>
            
            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                    For urgent matters, please join our Discord server directly.
                </Typography>
            </Box>
        </Container>
    );
};

export default ModMail;