import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { lightTheme, darkTheme } from './pages/components/themes';
import { CssBaseline, ThemeProvider, Box } from '@mui/material';
import { DarkModeProvider, useDarkMode } from './pages/components/DarkModeContext.js'; // Import DarkModeContext
import NavBar from './pages/components/NavBar.js'
import StartPage  from './pages/StartPage.js'
import LeaderboardPage from './pages/Leaderboard.js';
import ProfilePage from "./pages/ProfilePage";
import Authorised from './pages/status/Authorised.js';
import NotFound from './pages/status/NotFound.js';
import ServerError from './pages/status/ServerError.js';
import Register from './pages/Register.js';
import StaffPage from './pages/Staff.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import Footer from './pages/components/Footer.js';
import ModMail from './pages/ModMail.js';
import FAQPage from './pages/Faq.js';
import ContentCreatorsPage from './pages/ContentCreators.js';
function App() {
  return (
    <Router>
      <DarkModeProvider>
        <AppWithTheme />
      </DarkModeProvider>
    </Router>
  );
}

function AppWithTheme() {
  const { darkMode, toggleMode } = useDarkMode();
  const links = [
    {url: "/privacy-policy", text: "Privacy Policy"}
  ];

  const additionalContent = null
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",  // Full viewport height
        }}
      >
        <NavBar toggleMode={toggleMode} darkMode={darkMode} />
        <Box
          sx={{
            flex: 1,  // This allows the content area to expand
            paddingBottom: "20px",
          }}
        >
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/leaderboards" element={<LeaderboardPage />} />
            <Route path="/profiles/:profile_name" element={<ProfilePageWrapper />} />
            <Route path="/authorised" element={<Authorised />} />
            <Route path="/staff" element={<StaffPage />}/>
            <Route path ="/modmail" element={<ModMail />}/>
            <Route path="/register" element={<Register/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/content-creators" element={<ContentCreatorsPage/>}/>
            <Route path="/help" element={<FAQPage/>}/>
            <Route path="/faq" element={<FAQPage/>}/>
            <Route path="/404" element={<NotFound />} />
            <Route path="/500" element={<ServerError />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
        <Footer 
          links={links} 
          additionalContent={additionalContent} 
        />
      </Box>
    </ThemeProvider>
);
}
function ProfilePageWrapper() {
  const { profile_name } = useParams();
  return <ProfilePage profile_name={profile_name} />;
}
export default App