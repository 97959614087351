import React from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
import {useDarkMode, darkMode } from './DarkModeContext';


const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

function DiscordWidget() {
    const [widgetLink, setWidgetLink] = useState("")
    const { darkMode } = useDarkMode();
    useEffect(() => {
            const fetchInviteLink = async () => {
                try {
                    const response = await axios.get(`${apiUrl}/api/widget-link`);
                    setWidgetLink(response.data.link);
                } catch (error) {
                    setWidgetLink("https://discord.com/widget?id=1270738996187627571");
                    console.error("Error fetching invite link:", error);
                }
            };
        
            fetchInviteLink();
        }, [setWidgetLink, widgetLink]);
    return (
        <div>
        <iframe
            src={widgetLink + "&theme=" + (darkMode ? "dark" : "light") }
            width="350"
            height="500"
            allowtransparency="true"
            style={{ border: 0, marginTop: '20px', borderRadius: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            title="Discord Widget"
        />
        </div>
    );
}

export default DiscordWidget;