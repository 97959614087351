import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Link } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useDarkMode } from "./components/DarkModeContext"
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

// Username validation function
function isValidUsername(username) {
    const pattern = /^(?!.*[_.-]{2})(?![_.-])[a-zA-Z0-9_.-]{3,16}(?<![_.-])$/;
    return pattern.test(username);
}

const Register = () => {
    const { darkMode, toggleMode } = useDarkMode();
    const [nickname, setNickname] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [showEpicLogin, setShowEpicLogin] = useState(false);
    const [inputVisible, setInputVisible] = useState(false);
    const [shakeInput, setShakeInput] = useState(false);

    // Animate the input field after component mount
    useEffect(() => {
        // Short delay before showing the input for a nicer entrance
        const timer = setTimeout(() => {
            setInputVisible(true);
        }, 500);
        
        return () => clearTimeout(timer);
    }, []);

    // Check username validity whenever nickname changes
    useEffect(() => {
        if (nickname.length > 0) {
            setIsValid(isValidUsername(nickname));
        } else {
            setIsValid(false);
        }
    }, [nickname]);
    
    // Reset shake animation after it completes
    useEffect(() => {
        if (shakeInput) {
            const timer = setTimeout(() => {
                setShakeInput(false);
            }, 500); // Duration of shake animation
            return () => clearTimeout(timer);
        }
    }, [shakeInput]);

    // Handle form submission
    const handleRegister = async () => {
        setErrorMessage(""); // Clear previous errors
        
        if (!isValid) {
            // Trigger shake animation and reset input
            setShakeInput(true);
            setNickname("");
            setErrorMessage("Usernames must be between 3 and 16 characters long. They can only contain letters (A-Z, a-z), numbers (0-9), underscores (_), hyphens (-), and dots (.). Usernames cannot start or end with a dot (.) or a hyphen (-) and cannot contain consecutive dots (..), hyphens (--), or mixed sequences like (.- or -.)")
            return;
        }

        try {
            const response = await axios.post(
                "/api/register",
                { nickname },
                { withCredentials: true } 
            );
            handleProceed()
        } catch (error) {
            handleRegistrationError(error);
        }
    };

    // Handle registration API errors
    const handleRegistrationError = (error) => {
        if (error.response) {
            const { status, data } = error.response;

            // Trigger shake animation for all errors
            setShakeInput(true);
            setNickname("");

            if (status === 401 && data.error === "NOT_LOGGED_IN") {
                setErrorMessage("You must be logged into Discord to register.");
                window.location.href = "/login";
            } else if (status === 409 && data.error === "USER_ALREADY_REGISTERED") {
                const username = data.message.split(' ')[10];
                setErrorMessage(
                    <div>
                        <Typography variant="body1" color="error">
                            You have already registered!
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Go to your profile:{" "}
                            <Link href={`/profiles/${username}`} rel="noopener noreferrer">
                                {username}
                            </Link>
                        </Typography>
                    </div>
                );
            } else {
                setErrorMessage(data.message || "Registration failed.");
            }
        } else {
            setErrorMessage("An error occurred while registering.");
            setShakeInput(true);
            setNickname("");
        }
    };

    // Handle proceeding to Epic login after valid username
    const handleProceed = () => {
        // Trigger animation to swipe username field right and show Epic login
        setShowEpicLogin(true);
    };

    // Shake animation variants
    const shakeVariants = {
        shake: {
            x: [0, -10, 10, -10, 10, -5, 5, -2, 2, 0],
            transition: { duration: 0.5 }
        }
    };
    
    // Bezier transition for elements
    const bezierTransition = {
        type: "tween",
        duration: 0.8,
        ease: [0.25, 1, 0.5, 1] // Custom bezier curve for swooshing effect
    };

    return (
        <Container maxWidth="sm" style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            minHeight: '100vh',
            overflow: 'hidden', // Prevents horizontal scrollbar during animations
        }}>
            {/* Logo and Title - Fixed position at top */}
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '48px'
                }}
            >
                <Box sx={{ mb: 2 }}>
                    { darkMode ?
                        <img src="/logo.png" alt="OCE4Mans Logo" width="120" /> :
                        <img src="/light_logo.png" alt="OCE4Mans Logo" width="120" />
                    }
                </Box>
                <Typography variant="h4" component="h1" gutterBottom> {/* Prevent wrapping here*/}
                    Welcome to OCE4Mans
                </Typography>
            </motion.div>

            {/* Content container with fixed height to prevent layout shifts */}
            <Box sx={{ 
                position: 'relative',
                width: '100%', 
                height: '240px', // Fixed height to prevent layout shifts
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}>
                <AnimatePresence mode="wait">
                    {/* Username field animation */}
                    {inputVisible && !showEpicLogin && (
                        <motion.div
                            key="username-input"
                            initial={{ width: 0, opacity: 0 }}
                            animate={{ width: "100%", opacity: 1 }}
                            exit={{ 
                                x: '100vw', 
                                opacity: 1,
                                transition: bezierTransition
                            }}
                            transition={{ 
                                duration: 1.2, 
                                ease: "easeInOut",
                            }}
                            style={{ 
                                position: 'absolute',
                                width: "100%",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography 
                                variant="h6" 
                                sx={{ 
                                    mb: 2, 
                                    opacity: 0.8,
                                    textAlign: 'center'
                                }}
                            >
                                Pick a username
                            </Typography>
                            
                            <motion.div
                                variants={shakeVariants}
                                animate={shakeInput ? "shake" : ""}
                                style={{ width: '100%' }}
                            >
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    value={nickname}
                                    onChange={(e) => setNickname(e.target.value)}
                                    sx={{
                                        '& .MuiInput-underline:before': {
                                            borderBottomWidth: '2px',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomWidth: '2px',
                                            borderBottomColor: isValid ? 'green' : undefined,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: false,
                                    }}
                                />
                            </motion.div>
                            
                            {/* Continue button with reserved space */}
                            <Box sx={{ height: '56px', width: '100%', mt: 2 }}>
                                <AnimatePresence>
                                    {isValid && (
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.9 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.9 }}
                                            transition={{ duration: 0.3 }}
                                            style={{ width: '100%' }}
                                        >
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                fullWidth 
                                                onClick={handleRegister}
                                            >
                                                Continue
                                            </Button>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </Box>
                        </motion.div>
                    )}

                    {/* Epic Games login button animation */}
                    {showEpicLogin && (
                        <motion.div
                            key="epic-login"
                            initial={{ x: '-100vw' }}
                            animate={{ x: 0 }}
                            transition={bezierTransition}
                            style={{ 
                                position: 'absolute',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center',
                                width: '100%',
                                gap: 2
                            }}>
                                <Typography variant="h6" sx={{ opacity: 0.8 }}>
                                    Connect your Epic Games account
                                </Typography>
                                
                                <Button 
                                    variant="contained" 
                                    color="secondary"
                                    fullWidth
                                    href="/api/auth/epic/login"
                                    sx={{ 
                                        py: 1.5,
                                        backgroundImage: 'linear-gradient(to right, #5658DD, #7B68EE)',
                                    }}
                                >
                                    Log in with Epic Games
                                </Button>
                            </Box>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Box>

            {/* Error message container with fixed height */}
            <Box sx={{ 
                height: '120px', 
                width: '100%', 
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                overflow: 'hidden'
            }}>
                <AnimatePresence>
                    {errorMessage && (
                        <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.4 }}
                            style={{ width: '100%' }}
                        >
                            <Box textAlign="center">
                                <Typography color="error" variant="body2">
                                    {errorMessage}
                                </Typography>
                            </Box>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Box>

            {/* Privacy policy text - Fixed at bottom */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.7 }}
                transition={{ duration: 1, delay: 1 }}
                style={{ marginTop: '32px' }}
            >
                <Typography variant="body2" align="center">
                    By signing up you agree to the{" "}
                    <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </Link>.
                </Typography>
            </motion.div>
        </Container>
    );
};

export default Register;