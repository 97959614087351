import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Button,
    Divider,
    Box,
    IconButton,
    Typography,
    SwipeableDrawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    useMediaQuery,
    useTheme,
    Avatar,
    Menu,
    MenuItem,
    Tooltip,
    alpha,
    Fade,
    Zoom,
    Badge,
    Container,
    styled
} from '@mui/material';
import {
    Brightness4,
    Brightness7,
    Home as HomeIcon,
    EmojiEvents as LeaderboardIcon,
    HelpOutline as HelpIcon,
    Mail as MailIcon,
    People as StaffIcon,
    Videocam as ContentCreatorIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    Person as PersonIcon,
    AccountCircle
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth.js";
import { motion, AnimatePresence } from 'framer-motion';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

// Map of icons for each link
const linkIcons = {
    "/": <HomeIcon />,
    "/leaderboards": <LeaderboardIcon />,
    "/help": <HelpIcon />,
    "/modmail": <MailIcon />,
    "/staff": <StaffIcon />,
    "/content-creators": <ContentCreatorIcon />
};

const StyledAppBar = styled(AppBar)(({ theme, transparent }) => ({
    background: transparent ? 'transparent' :
        theme.palette.mode === 'dark'
            ? `linear-gradient(90deg, ${alpha(theme.palette.grey[900], 0.95)} 0%, ${alpha(theme.palette.grey[800], 0.9)} 100%)`
            : `linear-gradient(90deg, ${alpha(theme.palette.common.white, 0.97)} 0%, ${alpha(theme.palette.grey[100], 0.95)} 100%)`,
    backdropFilter: 'blur(10px)',
    boxShadow: transparent ? 'none' : `0 4px 20px ${alpha(theme.palette.common.black, theme.palette.mode === 'dark' ? 0.1 : 0.05)}`,
    borderBottom: transparent ? 'none' : `1px solid ${alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black, 0.1)}`,
    transition: 'all 0.3s ease',
}));

const NavButton = styled(Button)(({ theme, active }) => ({
    position: 'relative',
    padding: '6px 16px',
    margin: '0 4px',
    borderRadius: 8,
    fontWeight: 500,
    letterSpacing: '0.02em',
    textTransform: 'none',
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
    overflow: 'hidden',
    transition: 'all 0.2s ease',
    '&::after': active ? {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '40%',
        height: 3,
        borderRadius: '3px 3px 0 0',
        backgroundColor: theme.palette.primary.main,
    } : {},
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' 
            ? alpha(theme.palette.grey[600], 0.2)
            : alpha(theme.palette.grey[300], 0.5),
        transform: 'translateY(-2px)',
    },
}));

const AvatarButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
    border: `2px solid ${alpha(theme.palette.common.white, 0.2)}`,
    transition: 'transform 0.2s ease, border-color 0.2s ease',
    '&:hover': {
        transform: 'scale(1.05)',
        borderColor: theme.palette.secondary.main,
    },
}));

const LoginButton = styled(Button)(({ theme }) => ({
    borderRadius: 20,
    padding: '8px 16px',
    fontWeight: 600,
    textTransform: 'none',
    boxShadow: `0 4px 8px ${alpha(theme.palette.common.black, theme.palette.mode === 'dark' ? 0.15 : 0.08)}`,
    background: theme.palette.mode === 'dark'
        ? `linear-gradient(45deg, ${theme.palette.grey[800]}, ${theme.palette.grey[700]})`
        : `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.white,
    border: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
        background: theme.palette.mode === 'dark'
            ? `linear-gradient(45deg, ${theme.palette.grey[700]}, ${theme.palette.grey[600]})`
            : `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
        transform: 'translateY(-2px)',
        boxShadow: `0 6px 12px ${alpha(theme.palette.common.black, theme.palette.mode === 'dark' ? 0.2 : 0.1)}`,
    }
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 12,
        marginTop: 8,
        minWidth: 180,
        background: theme.palette.mode === 'dark'
            ? alpha(theme.palette.background.paper, 0.9)
            : alpha(theme.palette.background.paper, 0.97),
        backdropFilter: 'blur(10px)',
        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        boxShadow: `0 10px 30px ${alpha(theme.palette.common.black, theme.palette.mode === 'dark' ? 0.15 : 0.08)}`,
        '& .MuiMenuItem-root': {
            margin: '4px 8px',
            borderRadius: 8,
            transition: 'all 0.2s ease',
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
        },
    },
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? `linear-gradient(135deg, ${alpha(theme.palette.grey[900], 0.95)} 0%, ${alpha(theme.palette.grey[800], 0.8)} 100%)`
        : `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.5)} 0%, ${alpha(theme.palette.primary.main, 0.4)} 100%)`,
    padding: theme.spacing(4, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'radial-gradient(circle at 25% 25%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 60%)',
        pointerEvents: 'none',
    }
}));

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
    margin: '4px 8px',
    borderRadius: 8,
    backgroundColor: active ? alpha(theme.palette.grey[700], 0.2) : 'transparent',
    transition: 'all 0.2s ease',
    '&:hover': {
        backgroundColor: alpha(theme.palette.grey[700], 0.2),
        transform: 'translateX(4px)',
    },
    '& .MuiListItemIcon-root': {
        color: active ? theme.palette.grey[400] : theme.palette.text.primary,
        minWidth: 40,
    },
}));

const DrawerFooter = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    background: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(10px)',
    borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    boxShadow: `0 -5px 15px ${alpha(theme.palette.common.black, 0.05)}`,
    transition: 'all 0.3s ease',
    marginTop: 'auto'
}));

function NavBar({ toggleMode, darkMode }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [scrolled, setScrolled] = useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false);
    const location = useLocation();

    const open = Boolean(anchorEl);
    const { loggedIn, userData, setLoggedIn, setUserData, isLoading } = useAuth();

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Monitor scroll position to adjust navbar appearance
    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogin = async () => {
        window.location.href = `${apiUrl}/api/auth/discord/login`;
    };

    const handleLogout = async () => {
        try {
            setLogoutLoading(true);
            await fetch(`${apiUrl}/api/auth/discord/logout`, {
                method: "POST",
                credentials: "include",
            });
            // Close any open menus before logout completes
            handleMenuClose();
            if (drawerOpen) toggleDrawer();

            // Clear user data first
            setUserData(null);
            // Then update logged in state
            setTimeout(() => {
                setLoggedIn(false);
                setLogoutLoading(false);
            }, 300);

        } catch (error) {
            console.error("Logout failed:", error);
            setLogoutLoading(false);
        }
    };

    let logo_path = (darkMode ? "/logo.png" : "/light_logo.png");

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const isActive = (path) => {
        return location.pathname === path;
    };

    const links = [
        { label: "Home", to: "/", icon: <HomeIcon /> },
        { label: "Leaderboards", to: "/leaderboards", icon: <LeaderboardIcon /> },
        { label: "Help", to: "/help", icon: <HelpIcon /> },
        { label: "Mod Mail", to: "/modmail", icon: <MailIcon /> },
        { label: "Meet the Staff", to: "/staff", icon: <StaffIcon /> },
        { label: "Content Creators", to: "/content-creators", icon: <ContentCreatorIcon /> }
    ];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <StyledAppBar position="fixed" transparent={!scrolled && location.pathname === '/'}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{ minHeight: { xs: 64, md: 70 }, py: 1 }}>
                        {isMobile && (
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={toggleDrawer}
                                sx={{
                                    mr: 2,
                                    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                            >
                                <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                                    <img
                                        src={logo_path}
                                        alt="Logo"
                                        style={{
                                            height: '40px',
                                            marginRight: '12px',
                                            filter: 'drop-shadow(0 2px 5px rgba(0,0,0,0.2))'
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            fontWeight: 700,
                                            display: { xs: 'none', sm: 'block' },
                                            letterSpacing: '0.02em',
                                            color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
                                            textShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                        }}
                                    >
                                        OCE-4Mans
                                    </Typography>
                                </Box>
                            </motion.div>
                        </Box>

                        {!isMobile && (
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                {links.map((link) => (
                                    <NavButton
                                        key={link.to}
                                        component={Link}
                                        to={link.to}
                                        active={isActive(link.to)}
                                        startIcon={link.icon}
                                    >
                                        {link.label}
                                    </NavButton>
                                ))}
                            </Box>
                        )}

                        <Box sx={{ flexGrow: { xs: 1, md: 0 } }} />

                        <Tooltip title={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}>
                            <IconButton
                                onClick={toggleMode}
                                sx={{
                                    mx: 1,
                                    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
                                    backgroundColor: theme.palette.mode === 'dark' 
                                        ? alpha(theme.palette.grey[700], 0.2)
                                        : alpha(theme.palette.grey[300], 0.5),
                                    borderRadius: 2,
                                    '&:hover': {
                                        backgroundColor: theme.palette.mode === 'dark' 
                                            ? alpha(theme.palette.grey[700], 0.3)
                                            : alpha(theme.palette.grey[400], 0.3),
                                    }
                                }}
                            >
                                {darkMode ? <Brightness7 /> : <Brightness4 />}
                            </IconButton>
                        </Tooltip>

                        {!isLoading && (
                            <AnimatePresence mode="wait">
                                {!loggedIn ? (
                                    <motion.div
                                        key="login"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 20 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <LoginButton
                                            onClick={handleLogin}
                                            startIcon={<AccountCircle />}
                                        >
                                            Login with Discord
                                        </LoginButton>
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        key="avatar"
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Tooltip title={userData?.profileName || "Account"}>
                                            <AvatarButton onClick={handleMenuOpen}>
                                                <Avatar
                                                    src={userData?.avatarUrl || "/default-avatar.jpg"}
                                                    alt="User Avatar"
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                        boxShadow: '0 2px 8px rgba(0,0,0,0.2)'
                                                    }}
                                                />
                                            </AvatarButton>
                                        </Tooltip>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        )}

                        <StyledMenu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            MenuListProps={{
                                'aria-labelledby': 'user-menu-button',
                            }}
                        >
                            <MenuItem
                                component={Link}
                                to={userData?.profileName ? `/profiles/${userData.profileName}` : "/register"}
                                onClick={handleMenuClose}
                                sx={{ gap: 1.5 }}
                            >
                                <PersonIcon fontSize="small" color="primary" />
                                {userData?.profileName ? "My Profile" : "Register"}
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to={`/profiles/${userData?.profileName}#settings`}
                                onClick={handleMenuClose}
                                sx={{ gap: 1.5 }}
                            >
                                <SettingsIcon fontSize="small" color="action" />
                                Settings
                            </MenuItem>

                            <Divider sx={{ my: 1, mx: 1 }} />

                            <MenuItem
                                onClick={handleLogout}
                                disabled={logoutLoading}
                                sx={{ gap: 1.5, color: theme.palette.error.main }}
                            >
                                {logoutLoading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                        <Box sx={{ width: 20, height: 20, display: 'flex', justifyContent: 'center' }}>
                                            <motion.div
                                                animate={{ rotate: 360 }}
                                                transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                                            >
                                                <LogoutIcon fontSize="small" />
                                            </motion.div>
                                        </Box>
                                        Logging out...
                                    </Box>
                                ) : (
                                    <>
                                        <LogoutIcon fontSize="small" />
                                        Logout
                                    </>
                                )}
                            </MenuItem>
                        </StyledMenu>
                    </Toolbar>
                </Container>
            </StyledAppBar>

            {/* Add toolbar spacing */}
            <Toolbar />

            <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer}
                onOpen={() => setDrawerOpen(true)}
                PaperProps={{
                    sx: {
                        width: 280,
                        borderRadius: '0 16px 16px 0',
                        background: theme.palette.background.paper,
                        boxShadow: `8px 0 25px ${alpha(theme.palette.common.black, 0.15)}`,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }
                }}
            >
                <DrawerHeader>
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Box component={Link} to="/" onClick={toggleDrawer} sx={{ textDecoration: 'none' }}>
                            <img
                                src={logo_path}
                                alt="Logo"
                                style={{
                                    height: '80px',
                                    filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.2))'
                                }}
                            />
                        </Box>
                    </motion.div>

                    <Typography
                        variant='h5'
                        sx={{
                            mt: 2,
                            fontWeight: 700,
                            color: '#ffffff',
                            letterSpacing: '0.02em',
                            textShadow: '0 2px 4px rgba(0,0,0,0.2)'
                        }}
                    >
                        OCE-4Mans
                    </Typography>
                </DrawerHeader>

                <Box sx={{ 
                    flexGrow: 1, 
                    overflow: 'auto',
                    my: 2,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <List sx={{ px: 1 }}>
                        {links.map((link, index) => (
                            <motion.div
                                key={link.to}
                                initial={{ x: -20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ delay: index * 0.05, duration: 0.3 }}
                            >
                                <StyledListItem
                                    button
                                    component={Link}
                                    to={link.to}
                                    onClick={toggleDrawer}
                                    active={isActive(link.to)}
                                >
                                    <ListItemIcon>{link.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: isActive(link.to) ? 600 : 400,
                                                    color: isActive(link.to) ? theme.palette.primary.main : 'inherit'
                                                }}
                                            >
                                                {link.label}
                                            </Typography>
                                        }
                                    />
                                </StyledListItem>
                            </motion.div>
                        ))}
                    </List>
                </Box>

                <DrawerFooter>
                    {!isLoading && (
                        <>
                            {!loggedIn ? (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    startIcon={<AccountCircle />}
                                    onClick={handleLogin}
                                    sx={{
                                        borderRadius: 8,
                                        py: 1.5,
                                        textTransform: 'none',
                                        background: `linear-gradient(45deg, ${theme.palette.grey[800]}, ${theme.palette.grey[700]})`,
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
                                        }
                                    }}
                                >
                                    Login with Discord
                                </Button>
                            ) : (
                                <Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 2,
                                        p: 1,
                                        borderRadius: 2,
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Avatar
                                                src={userData?.avatarUrl || "/default-avatar.jpg"}
                                                alt="User Avatar"
                                                sx={{
                                                    width: 48,
                                                    height: 48,
                                                    border: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                                                }}
                                            />
                                            <Box>
                                                <Typography sx={{ fontWeight: 600, lineHeight: 1.2 }}>
                                                    {userData?.profileName || "Player"}
                                                </Typography>
                                                <Typography variant="body2" sx={{ opacity: 0.7, fontSize: '0.8rem' }}>
                                                    Discord Connected
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Tooltip title="View Profile">
                                            <IconButton
                                                size="small"
                                                component={Link}
                                                to={`/profiles/${userData?.profileName}`}
                                                onClick={toggleDrawer}
                                                sx={{
                                                    bgcolor: alpha(theme.palette.background.paper, 0.7),
                                                    '&:hover': { bgcolor: alpha(theme.palette.background.paper, 0.9) }
                                                }}
                                            >
                                                <PersonIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            component={Link}
                                            to={`/profiles/${userData?.profileName}#settings`}
                                            onClick={toggleDrawer}
                                            startIcon={<SettingsIcon />}
                                            sx={{
                                                borderRadius: 8,
                                                py: 1,
                                                textTransform: 'none',
                                                borderColor: alpha(theme.palette.text.primary, 0.3),
                                                '&:hover': {
                                                    borderColor: theme.palette.text.primary,
                                                    backgroundColor: alpha(theme.palette.text.primary, 0.05),
                                                }
                                            }}
                                        >
                                            Settings
                                        </Button>

                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={handleLogout}
                                            disabled={logoutLoading}
                                            color="error"
                                            startIcon={
                                                logoutLoading ? (
                                                    <motion.div
                                                        animate={{ rotate: 360 }}
                                                        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                                                    >
                                                        <LogoutIcon fontSize="small" />
                                                    </motion.div>
                                                ) : <LogoutIcon />
                                            }
                                            sx={{
                                                borderRadius: 8,
                                                py: 1,
                                                textTransform: 'none',
                                                '&:hover': {
                                                    backgroundColor: alpha(theme.palette.error.main, 0.05),
                                                }
                                            }}
                                        >
                                            {logoutLoading ? 'Logging out...' : 'Logout'}
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </DrawerFooter>
            </SwipeableDrawer>
        </Box>
    );
}

export default NavBar;