import React, { useState, useEffect } from 'react';
import { Button, Typography, Container, Box, Fade, IconButton, CircularProgress } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DarkModeProvider, useDarkMode } from './components/DarkModeContext';
import DiscordWidget from "./components/DiscordWidget";
import axios from "axios";

import useAuth from './hooks/useAuth';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

function StartPage() {
    console.log(`Using API URL: ${apiUrl}`);
    const [fadeIn, setFadeIn] = useState(false);
    const { darkMode, toggleMode } = useDarkMode();
    const { loggedIn, userData, setLoggedIn, setUserData, isLoading } = useAuth();
    const [inviteLink, setInviteLink] = useState("");
    const [widgetVisible, setWidgetVisible] = useState(true);
    const widgetRef = React.useRef(null);

    useEffect(() => {
        const fetchInviteLink = async () => {
            try {
                const response = await axios(`${apiUrl}/api/invite-link`);
                setInviteLink(response.data.link);
            } catch (error) {
                setInviteLink("https://discord.gg/vWCTTeBYRE");
                console.error("Error fetching invite link:", error);
            }
        };
    
        fetchInviteLink();
    }, []);

    const handleButtonClick = () => {
        if (!loggedIn) {
            window.location.href = `${apiUrl}/api/auth/discord/login`;
        } else if (userData.profileName) {
            window.location.href = `/profiles/${userData.profileName}`; 
        } else {
            window.location.href = "/register";
        }
    };

    const getButtonText = () => {
        if (!loggedIn) {
            return "Login with Discord";
        } else if (userData.profileName) {
            return "Go to your profile";
        } else {
            return "Register for 4 Mans";
        }
    };

    useEffect(() => {
        setFadeIn(true);
    }, []);

    const toggleWidget = () => {
        setWidgetVisible(!widgetVisible);
    };

    let logo_path = (darkMode ? "/logo.png" : "/light_logo.png");

    return (
        <Fade in={fadeIn} timeout={2000}>
            <Box
                sx={{
                    minHeight: "100vh", 
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    pb: 8,
                }}
            >
                <Box
                    component="a"
                    href={apiUrl + "/"}
                >   
                    <Box
                        component="img"
                        src={logo_path}
                        alt="OCE 4Mans logo"
                        key={darkMode ? 'dark' : 'light'} 
                        sx={{
                            height: { xs: "30vh", sm: "40vh" },
                            marginRight: "16px",
                            transition: "transform 0.3s ease-in-out", 
                            "&:hover": {
                                transform: "scale(1.05)", 
                                cursor: "pointer",
                            },
                        }}
                    />
                </Box>
                <Typography 
                    variant="h2" 
                    gutterBottom
                    sx={{
                        fontSize: { xs: "2rem", sm: "3.75rem" }
                    }}
                >
                    Welcome to OCE 4Mans
                </Typography>
                <Box 
                    sx={{ 
                        mt: 2,
                        display: "flex", 
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2
                    }}
                >
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={() => window.open(inviteLink, "_blank", "noopener,noreferrer")}
                        sx={{ 
                            width: { xs: "200px", sm: "auto" }
                        }}
                    >
                        Join the server
                    </Button>

                    {isLoading ? (
                        <Button 
                            variant="outlined" 
                            color="primary"
                            disabled
                            sx={{ 
                                width: { xs: "200px", sm: "auto" },
                                minWidth: "200px",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <CircularProgress size={24} />
                        </Button>
                    ) : (
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={handleButtonClick}
                            sx={{ 
                                width: { xs: "200px", sm: "auto" },
                                minWidth: "200px"
                            }}
                        >
                            {getButtonText()}
                        </Button>
                    )}
                </Box>
                
                {/* New approach: Split into two components - toggle button and panel */}
                {/* Toggle Button - Always visible */}
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        right: 0,
                        transform: "translateY(-50%)",
                        display: { xs: "none", sm: "none", md: "none", lg: "block" },
                        zIndex: 1001,
                    }}
                >
                    <IconButton
                        onClick={toggleWidget}
                        sx={{
                            backgroundColor: darkMode ? "#1E1E1E" : "#FFF",
                            boxShadow: 2,
                            borderRadius: "50% 0 0 50%",
                            width: "40px",
                            height: "40px",
                            "&:hover": {
                                backgroundColor: darkMode ? "#333" : "#f5f5f5",
                            },
                        }}
                    >
                        {widgetVisible ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </Box>
                
                {/* Discord Widget Panel */}
                <Box
                    ref={widgetRef}
                    sx={{
                        position: "fixed",
                        top: "50%",
                        right: widgetVisible ? "45px" : "-100%", // Use percentage to ensure it's off-screen
                        transform: "translateY(-50%)",
                        display: { xs: "none", sm: "none", md: "none", lg: "block" },
                        backgroundColor: darkMode ? "#1E1E1E" : "#FFF",
                        padding: "1.5vh 1.5vw",
                        boxShadow: 3,
                        borderRadius: "8px",
                        transition: "right 0.5s ease-in-out", // Smooth transition for sliding
                        zIndex: 1000,
                        maxWidth: "30vw",
                        maxHeight: "70vh",
                        overflow: "auto"
                    }}
                >
                    <DiscordWidget />
                </Box>
            </Box>
        </Fade>
    );
}

export default StartPage;