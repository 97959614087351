import { createTheme } from "@mui/material"

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: "#f5f5f5", // Light gray instead of pure white
            paper: "#ffffff",  // Used for Paper components
        },
        primary: {
            main: '#f28100',  
        },
        secondary: {
            main: '#c505fa',  
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                },
            },
        },
    },
}
);

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#f5e107',  
        },
        secondary: {
            main: '#ce18db',  
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                },
            },
        },
    },
}
);