// NotFound.jsx - 404 Page with Dark Mode Support
import React from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import { useDarkMode } from '../components/DarkModeContext';

// makeStyles with a function that receives the theme and darkMode
const useStyles = (darkMode) => makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
    },
    paper: {
        padding: theme.spacing(6),
        borderRadius: 16,
        boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
        maxWidth: 500,
        width: '100%',
        background: darkMode
            ? 'linear-gradient(145deg, #2d2d2d, #1e1e1e)'
            : 'linear-gradient(145deg, #ffffff, #f5f5f5)',
    },
    icon: {
        fontSize: '4.5rem',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
        animation: '$float 3s ease-in-out infinite',
    },
    title: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    message: {
        marginBottom: theme.spacing(3),
        color: darkMode ? '#e0e0e0' : theme.palette.text.secondary,
    },
    button: {
        borderRadius: 12,
        textTransform: 'none',
        fontWeight: 600,
        boxShadow: '0 4px 14px 0 rgba(0, 118, 255, 0.39)',
        padding: theme.spacing(1, 3),
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 20px rgba(0, 118, 255, 0.23)',
        },
    },
    '@keyframes float': {
        '0%': {
            transform: 'translateY(0px)',
        },
        '50%': {
            transform: 'translateY(-10px)',
        },
        '100%': {
            transform: 'translateY(0px)',
        },
    },
}));

const NotFound = () => {
    const { darkMode } = useDarkMode();
    const classes = useStyles(darkMode)();
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container className={classes.root}>
            <Paper
                elevation={0}
                className={classes.paper}
                style={{ color: darkMode ? '#ffffff' : 'inherit' }}
            >
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <ExploreOffIcon className={classes.icon} />

                    <Typography variant="h4" className={classes.title}>
                        Oops! Page Not Found
                    </Typography>

                    <Typography variant="body1" className={classes.message}>
                        It seems you've wandered into uncharted territory. The page you're looking for may have moved or doesn't exist anymore.
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleGoHome}
                        disableElevation
                    >
                        Take Me Home
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default NotFound;