import { useState, useEffect, useRef } from "react";
const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";;
function useAuth() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const isMounted = useRef(true);
  
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Function to refresh token
  console.log(`Using API URL: ${apiUrl}`)
  const refreshToken = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/discord/refresh-token`, {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      console.log("Token refreshed successfully");
      if (isMounted.current) {
        await fetchAuthStatus();
      }

      return data.access_token;
    } catch (error) {
      console.error("Error refreshing token:", error);
      setLoggedIn(false);
      setUserData(null);
      setIsLoading(false);
      return null;
    }
  };


  const fetchAuthStatus = async (retry = true) => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/discord/status`, {
        method: "GET",
        credentials: "include",
      });
  
      if (response.status === 401 && retry) {
        const newToken = await refreshToken();
        if (newToken) {
          return fetchAuthStatus(false);
        }
      }
  
      if (!response.ok) {
        throw new Error("Failed to fetch auth status");
      }
  
      const data = await response.json();
  
      if (isMounted.current) {
        setLoggedIn(data.logged_in);
        console.log(data)
        setUserData(
          data.logged_in
            ? {
                userId: data.player.id,
                profileName: data.player.username,
                avatarUrl: data.player.avatar
                  ? `https://cdn.discordapp.com/avatars/${data.player.id}/${data.player.avatar}.png`
                  : "/default-avatar.png",
              }
            : null
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching authentication status:", error);
      if (isMounted.current) {
        setLoggedIn(false);
        setUserData(null);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAuthStatus(); 

    const interval = setInterval(async () => {
      await refreshToken();
    }, 55 * 60 * 1000); 

    return () => clearInterval(interval);
  }, []);
  

  return { loggedIn, userData, setLoggedIn, setUserData, isLoading };
}

export default useAuth;