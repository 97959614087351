import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Fade,
    CircularProgress,
    useTheme,
    alpha,
    Divider,
    TextField,
    InputAdornment,
    Paper,
    Button,
    Chip
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    Search as SearchIcon,
    LiveHelp as LiveHelpIcon,
    Category as CategoryIcon,
    Error as ErrorIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

// Categories with matching colors
const categories = [
    { id: 'general', label: 'General', color: '#3f51b5' },
    { id: 'gameplay', label: 'Gameplay', color: '#4caf50' },
    { id: 'ranks', label: 'Ranks', color: '#ff9800' },
    { id: 'technical', label: 'Technical', color: '#f44336' },
    { id: 'discord', label: 'Discord', color: '#7289da' },
];

const FAQPage = () => {
    const [faqItems, setFaqItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [expanded, setExpanded] = useState(false);

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    useEffect(() => {
        const fetchFAQ = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${apiUrl}/api/faq`);

                if (!response.ok) {
                    throw new Error('Failed to fetch FAQ data');
                }

                const data = await response.json();
                setFaqItems(data);
                setError(null);
            } catch (err) {
                console.error('Error fetching FAQ data:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchFAQ();
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter FAQ items based on search term and selected category
    const filteredFAQ = faqItems.filter(item => {
        const matchesSearch =
            item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.answer.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesCategory =
            selectedCategory === 'all' ||
            item.category === selectedCategory;

        return matchesSearch && matchesCategory;
    });

    // Empty state content
    const emptyStateContent = (
        <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            sx={{
                textAlign: 'center',
                py: 8,
                px: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
            }}
        >
            <LiveHelpIcon
                sx={{
                    fontSize: 80,
                    color: alpha(theme.palette.primary.main, 0.7),
                    filter: `drop-shadow(0 4px 8px ${alpha(theme.palette.primary.main, 0.4)})`,
                }}
            />

            <Typography variant="h4" fontWeight="bold" color="primary">
                FAQ Coming Soon
            </Typography>

            <Typography variant="body1" sx={{ maxWidth: 600, mb: 2, color: theme.palette.text.secondary }}>
                We're currently working on compiling a comprehensive list of frequently asked questions to help our community.
                This section will soon be filled with helpful information about our ranking system, gameplay, technical support, and more.
            </Typography>

            <Button
                variant="contained"
                color="primary"
                href="/modmail"
                sx={{
                    borderRadius: 8,
                    px: 4,
                    py: 1.5,
                    textTransform: 'none',
                    boxShadow: `0 4px 14px ${alpha(theme.palette.primary.main, 0.4)}`,
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.6)}`,
                    }
                }}
            >
                Ask a Question
            </Button>
        </Box>
    );

    // Error state content
    const errorStateContent = (
        <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            sx={{
                textAlign: 'center',
                py: 8,
                px: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
            }}
        >
            <ErrorIcon
                sx={{
                    fontSize: 80,
                    color: theme.palette.error.main,
                    filter: `drop-shadow(0 4px 8px ${alpha(theme.palette.error.main, 0.4)})`,
                }}
            />

            <Typography variant="h4" fontWeight="bold" color="error">
                Oops! Something went wrong
            </Typography>

            <Typography variant="body1" sx={{ maxWidth: 600, mb: 2, color: theme.palette.text.secondary }}>
                We're having trouble loading the FAQ content right now. Please try again later or contact our support team for assistance.
            </Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={() => window.location.reload()}
                sx={{
                    borderRadius: 8,
                    px: 4,
                    py: 1.5,
                    textTransform: 'none',
                    boxShadow: `0 4px 14px ${alpha(theme.palette.primary.main, 0.4)}`,
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.6)}`,
                    }
                }}
            >
                Try Again
            </Button>
        </Box>
    );

    return (
        <Container maxWidth="lg" sx={{ my: 5 }}>
            <Box
                component={motion.div}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                sx={{
                    textAlign: 'center',
                    mb: 5
                }}
            >
                <Typography
                    variant="h2"
                    component="h1"
                    fontWeight="bold"
                    sx={{
                        mb: 2,
                        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        textShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    }}
                >
                    Frequently Asked Questions
                </Typography>

                <Typography variant="h6" color="textSecondary" sx={{ maxWidth: 700, mx: 'auto' }}>
                    Find answers to the most common questions about OCE-4Mans
                </Typography>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
                    <CircularProgress size={60} />
                </Box>
            ) : error || faqItems.length === 0 ? (
                <Paper
                    elevation={3}
                    sx={{
                        borderRadius: 4,
                        overflow: 'hidden',
                        background: isDarkMode
                            ? alpha(theme.palette.background.paper, 0.8)
                            : alpha(theme.palette.background.paper, 0.9),
                        backdropFilter: 'blur(10px)',
                        boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
                    }}
                >
                    {error ? errorStateContent : emptyStateContent}
                </Paper>
            ) : (
                <>
                    <Box
                        component={motion.div}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        sx={{ mb: 4 }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                borderRadius: 4,
                                background: isDarkMode
                                    ? alpha(theme.palette.background.paper, 0.8)
                                    : alpha(theme.palette.background.paper, 0.9),
                                backdropFilter: 'blur(10px)',
                                boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search questions or answers..."
                                value={searchTerm}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        borderRadius: 3,
                                        backdropFilter: 'blur(8px)',
                                        '& fieldset': {
                                            borderColor: alpha(theme.palette.divider, 0.3),
                                        },
                                    }
                                }}
                            />
                        </Paper>
                    </Box>

                    <Box
                        component={motion.div}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: 1,
                            mb: 4
                        }}
                    >
                        <Chip
                            label="All Categories"
                            clickable
                            onClick={() => handleCategoryClick('all')}
                            color={selectedCategory === 'all' ? 'primary' : 'default'}
                            variant={selectedCategory === 'all' ? 'filled' : 'outlined'}
                            icon={<CategoryIcon />}
                            sx={{
                                fontWeight: 500,
                                borderRadius: 3,
                                px: 1,
                                '&.MuiChip-colorPrimary': {
                                    boxShadow: `0 2px 8px ${alpha(theme.palette.primary.main, 0.4)}`,
                                }
                            }}
                        />

                        {categories.map(category => (
                            <Chip
                                key={category.id}
                                label={category.label}
                                clickable
                                onClick={() => handleCategoryClick(category.id)}
                                color={selectedCategory === category.id ? 'primary' : 'default'}
                                variant={selectedCategory === category.id ? 'filled' : 'outlined'}
                                sx={{
                                    fontWeight: 500,
                                    borderRadius: 3,
                                    px: 1,
                                    ...(selectedCategory === category.id && {
                                        background: category.color,
                                        color: '#ffffff',
                                        boxShadow: `0 2px 8px ${alpha(category.color, 0.4)}`,
                                    })
                                }}
                            />
                        ))}
                    </Box>

                    {filteredFAQ.length > 0 ? (
                        <Box
                            component={motion.div}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            {filteredFAQ.map((item, index) => {
                                const categoryInfo = categories.find(cat => cat.id === item.category) || categories[0];

                                return (
                                    <Accordion
                                        key={item.id}
                                        expanded={expanded === `panel${item.id}`}
                                        onChange={handleChange(`panel${item.id}`)}
                                        sx={{
                                            mb: 2,
                                            borderRadius: '16px !important',
                                            overflow: 'hidden',
                                            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                                            boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.05)}`,
                                            '&:before': {
                                                display: 'none',
                                            },
                                            background: isDarkMode
                                                ? alpha(theme.palette.background.paper, 0.8)
                                                : alpha(theme.palette.background.paper, 0.9),
                                            backdropFilter: 'blur(10px)',
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                boxShadow: `0 6px 24px ${alpha(theme.palette.common.black, 0.1)}`,
                                                transform: expanded === `panel${item.id}` ? 'none' : 'translateY(-2px)',
                                            },
                                        }}
                                        component={motion.div}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.05 + 0.4 }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <Box
                                                    sx={{
                                                        transition: 'all 0.3s ease',
                                                        color: expanded === `panel${item.id}` ? theme.palette.primary.main : 'inherit',
                                                    }}
                                                >
                                                    <ExpandMoreIcon />
                                                </Box>
                                            }
                                            aria-controls={`panel${item.id}-content`}
                                            id={`panel${item.id}-header`}
                                            sx={{
                                                '& .MuiAccordionSummary-content': {
                                                    alignItems: 'center',
                                                },
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <Chip
                                                    label={categoryInfo.label}
                                                    size="small"
                                                    sx={{
                                                        background: alpha(categoryInfo.color, 0.1),
                                                        color: categoryInfo.color,
                                                        fontWeight: 600,
                                                        fontSize: '0.7rem',
                                                        borderRadius: 4,
                                                        border: `1px solid ${alpha(categoryInfo.color, 0.3)}`,
                                                    }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: expanded === `panel${item.id}` ? theme.palette.primary.main : 'inherit',
                                                        transition: 'all 0.3s ease',
                                                    }}
                                                >
                                                    {item.question}
                                                </Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pt: 0, pb: 3, px: 3 }}>
                                            <Divider sx={{ mb: 2 }} />
                                            <Typography
                                                dangerouslySetInnerHTML={{ __html: item.answer }}
                                                sx={{
                                                    color: theme.palette.text.secondary,
                                                    '& a': {
                                                        color: theme.palette.primary.main,
                                                        textDecoration: 'none',
                                                        fontWeight: 500,
                                                        '&:hover': {
                                                            textDecoration: 'underline',
                                                        }
                                                    }
                                                }}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </Box>
                    ) : (
                        <Paper
                            elevation={3}
                            sx={{
                                p: 5,
                                borderRadius: 4,
                                textAlign: 'center',
                                background: isDarkMode
                                    ? alpha(theme.palette.background.paper, 0.8)
                                    : alpha(theme.palette.background.paper, 0.9),
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                No matching FAQ items found
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                Try adjusting your search or category filters
                            </Typography>
                        </Paper>
                    )}
                </>
            )}
        </Container>
    );
};

export default FAQPage;