import React from "react";
import { Box, Typography, Link } from "@mui/material";

const Footer = ({ links, additionalContent }) => {
    console.log(links);
    console.log(additionalContent);
    return (
        <Box
            component="footer"
            sx={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#333",
                color: "#fff",
                textAlign: "center",
            }}
        >
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6">OCE 4 Mans</Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                    {links && links.map((link, index) => (
                        
                        <Link key={index} href={link.url} sx={{ color: "#fff", textDecoration: "none" }}>
                            {link.text}
                        </Link>
                    ))}
                </Box>
            </Box>
            {additionalContent && (
                <Box sx={{ mt: 2 }}>
                    {additionalContent}
                </Box>
            )}
            <Typography variant="body2" sx={{ mt: 3 }}>
                &copy; {new Date().getFullYear()} OCE4Mans. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;