import React, { useState } from 'react';
import {
    Container,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Paper,
    useTheme,
    useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PrivacyPolicy = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const sections = [
        {
            id: 'introduction',
            title: '1. Introduction',
            content: `Welcome to RL4mans! Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains what data we collect, how we use it, and your rights regarding that data when you use RL4mans and link your Epic Games account or interact through Discord. By using RL4mans, you agree to the terms of this Privacy Policy. If you don't agree, you can stop using our services at any time.`
        },
        {
            id: 'data-collection',
            title: '2. What Data We Collect',
            content: (
                <>
                    <Typography variant="body1" paragraph>
                        When you use RL4mans, we may collect the following types of data:
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">From Discord:</Typography>
                    <Typography variant="body1" paragraph>
                        • Discord ID – Used to identify your Discord account and link it to your RL4mans profile.<br />
                        • Discord Connections – We may check your Discord connections to verify linked Epic Games accounts and improve your experience.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">From Epic Games:</Typography>
                    <Typography variant="body1" paragraph>
                        • Epic Games ID – Used to identify your Epic Games account.<br />
                        • Display Name – Used to show your username in the app.<br />
                        • Rank Data – Used to determine and display your rank in RL4mans.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">Other Data:</Typography>
                    <Typography variant="body1">
                        • Email Address – If provided, used for account recovery and communication.<br />
                        • Usage Data – We may collect information about how you use RL4mans to improve the service.
                    </Typography>
                </>
            )
        },
        {
            id: 'data-usage',
            title: '3. How We Use Your Data',
            content: (
                <>
                    <Typography variant="body1" paragraph>
                        We use the data we collect to:
                    </Typography>
                    <Typography variant="body1">
                        • Verify your identity through your Discord and Epic Games accounts.<br />
                        • Display your rank and other gameplay-related information in RL4mans.<br />
                        • Provide customer support and improve the overall user experience.<br />
                        • Link your Epic Games account to your Discord account for streamlined login and data consistency.
                    </Typography>
                </>
            )
        },
        {
            id: 'data-management',
            title: '4. How You Can Manage Your Data',
            content: (
                <>
                    <Typography variant="body1" paragraph>
                        You are in control of your data! Here's how you can manage it:
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">Unlink Epic Games Account:</Typography>
                    <Typography variant="body1" paragraph>
                        You can unlink your Epic Games account from the settings page. However, you won't be able to play RL4mans until you relink an Epic Games account.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">Delete Your RL4mans Account:</Typography>
                    <Typography variant="body1" paragraph>
                        You can delete your RL4mans account in the settings. This will:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Remove all stored data related to your account.<br />
                        • Permanently unlink your Epic Games and Discord accounts (if connected).<br />
                        • Delete any rank and gameplay data associated with your account.
                    </Typography>
                    <Typography variant="body1">
                        Note: Your Discord ID will still be retained to prevent rank manipulation by deleting and re-registering an account.
                    </Typography>
                </>
            )
        },
        {
            id: 'data-protection',
            title: '5. How We Store and Protect Your Data',
            content: `We store your data in a password-protected, encrypted database. We take reasonable steps to protect your data from unauthorized access, including encryption and access controls.`
        },
        {
            id: 'data-retention',
            title: '6. How Long We Keep Your Data',
            content: `We retain your data for as long as your RL4mans account is active. If you delete your account, we will delete all related data, including any linked Epic Games and Discord information, within 30 days — except for your Discord ID, which we retain to prevent rank manipulation.`
        },
        {
            id: 'data-sharing',
            title: '7. How We Share Your Data',
            content: (
                <>
                    <Typography variant="body1" paragraph>
                        We do not sell your data. We may share your data only when necessary to:
                    </Typography>
                    <Typography variant="body1">
                        • Comply with legal obligations.<br />
                        • Work with trusted service providers (e.g., for hosting and analytics) who are bound by confidentiality agreements.<br />
                        • Verify account information through Discord or Epic Games as part of the login or linking process.
                    </Typography>
                </>
            )
        },
        {
            id: 'your-rights',
            title: '8. Your Rights',
            content: (
                <>
                    <Typography variant="body1" paragraph>
                        You have the right to:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Access the data we store about you.<br />
                        • Request deletion of your account and data.<br />
                        • Unlink your Epic Games account at any time.<br />
                        • Manage Discord permissions directly through Discord.
                    </Typography>
                    <Typography variant="body1">
                        If you'd like to make a request, contact us using the info below.
                    </Typography>
                </>
            )
        },
        {
            id: 'policy-changes',
            title: '9. Changes to This Privacy Policy',
            content: `We may update this Privacy Policy from time to time. If we make any significant changes, we'll notify you through the app or via email.`
        },
        {
            id: 'contact',
            title: '10. How to Contact Us',
            content: `If you have any questions about this Privacy Policy, you can contact us at: support@rl4mans.com`
        }
    ];

    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mt: 4, mb: 4 }}>
                <Box textAlign="center" mb={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        RL4mans Privacy Policy
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Last updated: March 12, 2025
                    </Typography>
                </Box>
                <Divider sx={{ mb: 4 }} />

                {sections.map((section, index) => (
                    <Accordion
                        key={section.id}
                        expanded={expanded === section.id}
                        onChange={handleChange(section.id)}
                        sx={{ mb: 1 }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${section.id}-content`}
                            id={`${section.id}-header`}
                        >
                            <Typography variant="h6">{section.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {typeof section.content === 'string' ? (
                                <Typography variant="body1">{section.content}</Typography>
                            ) : (
                                section.content
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;