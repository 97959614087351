    import React, { createContext, useState, useContext } from 'react';

    // Create the DarkMode context
    const DarkModeContext = createContext();

    // Create a provider to wrap the app and manage dark mode
    export const DarkModeProvider = ({ children }) => {
        const savedTheme = localStorage.getItem('theme');
        const [darkMode, setDarkMode] = useState(savedTheme ? savedTheme === 'dark' : true);

        const toggleMode = () => {
            setDarkMode((prev) => {
                const newMode = !prev;
                localStorage.setItem('theme', newMode ? 'dark' : 'light');
                return newMode;
            });
        };

        return (
            <DarkModeContext.Provider value={{ darkMode, toggleMode }}>
                {children}
            </DarkModeContext.Provider>
        );
    };

    // Create a custom hook to use dark mode state
    export const useDarkMode = () => {
        const context = useContext(DarkModeContext);
        if (!context) {
            throw new Error('useDarkMode must be used within a DarkModeProvider');
        }
        return context;
    };