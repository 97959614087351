import { useState, useEffect } from 'react';
import { 
  Box, 
  Tabs, 
  Tab, 
  Typography, 
  Link, 
  useTheme, 
  useMediaQuery, 
  Skeleton,
  Paper,
  Chip,
  Divider,
  alpha
} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { motion } from 'framer-motion';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

async function addPlaces(data) {
    const newData = {};
    let player;
    
    // Add a global leaderboard
    newData['GLOBAL'] = [];
    let globalPlayers = [];
    
    // Collect players from all ranks for global leaderboard
    for (let rank of Object.keys(data)) {
        if (rank === "ranks") {
            continue;
        }
        
        newData[rank] = [];
        for (let i = 0; i < data[rank].length; i++) {
            player = data[rank][i];
            player.place = i + 1;
            player.total_played = player.matches_won + player.matches_lost;
            // Store winrate as a number for proper sorting
            player.winrate_num = player.total_played === 0 ? 0 : Math.round((player.matches_won / player.total_played) * 1000) / 10;
            // Format winrate for display
            player.winrate = player.total_played === 0 ? "0%" : player.winrate_num + "%";
            newData[rank].push(player);
            
            // Add to global array
            globalPlayers.push({...player, rank});
        }
    }
    
    // Sort global players by MMR and add place
    globalPlayers.sort((a, b) => b.mmr - a.mmr);
    for (let i = 0; i < globalPlayers.length; i++) {
        globalPlayers[i].place = i + 1;
    }
    
    newData['GLOBAL'] = globalPlayers;
    return newData;
}

// Get rank color based on rank name
const getRankColor = (rank) => {
    const rankColors = {
        'A': '#F44336', // Red
        'B': '#FF9800', // Orange
        'C': '#FFEB3B', // Yellow
        'D': '#4CAF50', // Green
        'GLOBAL': '#2196F3', // Blue
        'S': '#9C27B0', // Purple
        'X': '#FF5722', // Deep Orange
    };
    
    return rankColors[rank] || '#9C27B0'; // Purple default
};

// Custom toolbar component
function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ px: 2, py: 1 }}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function LeaderboardPage() {
    const [leaderboardData, setLeaderboardData] = useState({});
    const [ranks, setRanks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRank, setSelectedRank] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/leaderboards`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                const data = await response.json();
                const updatedData = await addPlaces(data);
                setLeaderboardData(updatedData);

                const rankKeys = ['GLOBAL', ...data["ranks"].reverse()];
                setRanks(rankKeys);
                setLoading(false);

                // Set the selected rank based on the URL hash
                const hash = location.hash.replace('#rank', '').toUpperCase();
                if (rankKeys.includes(hash)) {
                    setSelectedRank(hash);
                } else {
                    setSelectedRank(rankKeys[0]);
                }
            } catch (error) {
                console.error('Error fetching leaderboard data:', error);
            }
        };

        fetchData();
    }, [location.hash]);

    const handleTabChange = (event, newValue) => {
        setSelectedRank(newValue);
        navigate(`#rank${newValue.toLowerCase()}`);
    };

    // Define columns with consistent widths and alignment
    const columns = [
        { 
            field: 'place', 
            headerName: 'Place', 
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const place = params.value;
                if (place <= 3) {
                    const colors = ['#FFD700', '#C0C0C0', '#CD7F32']; // Gold, Silver, Bronze
                    return (
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            bgcolor: colors[place - 1],
                            color: place === 1 ? 'black' : 'white',
                            borderRadius: '50%',
                            width: 28,
                            height: 28,
                            fontWeight: 'bold'
                        }}>
                            {place}
                        </Box>
                    );
                }
                return place;
            }
        },
        {
            field: 'username',
            headerName: 'Player',
            width: 200,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Link 
                        href={`${apiUrl}/profiles/${params.value}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        sx={{ 
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': {
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        {params.row.place <= 3 && (
                            <EmojiEventsIcon 
                                fontSize="small" 
                                sx={{ 
                                    mr: 1, 
                                    color: params.row.place === 1 ? '#FFD700' : params.row.place === 2 ? '#C0C0C0' : '#CD7F32' 
                                }} 
                            />
                        )}
                        {params.value}
                    </Link>
                </Box>
            )
        },
        { 
            field: 'mmr', 
            headerName: 'MMR', 
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Chip 
                        label={params.value} 
                        size="small" 
                        sx={{ 
                            fontWeight: 'bold',
                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                            color: theme.palette.primary.main,
                            height: 24
                        }} 
                    />
                </Box>
            )
        },
        // Only show rank in global leaderboard
        ...(selectedRank === 'GLOBAL' ? [{
            field: 'rank',
            headerName: 'Rank',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Chip 
                        label={params.value} 
                        size="small" 
                        sx={{ 
                            bgcolor: alpha(getRankColor(params.value), 0.2),
                            color: getRankColor(params.value),
                            fontWeight: 'bold',
                            height: 24
                        }} 
                    />
                </Box>
            )
        }] : []),
        { 
            field: 'matches_won', 
            headerName: 'Wins', 
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Typography sx={{ color: 'success.main', fontWeight: 'bold' }}>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        { 
            field: 'matches_lost', 
            headerName: 'Losses', 
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        { 
            field: 'total_played', 
            headerName: 'Total', 
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Typography>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        { 
            field: 'winrate', 
            headerName: 'Win %', 
            width: 80,
            headerAlign: 'center',
            align: 'center',
            // Use the numeric winrate for sorting with a safe fallback
            valueGetter: (params) => {
                // Make sure we have a valid row and winrate_num property
                return params.row?.winrate_num ?? 0;
            },
            renderCell: (params) => {
                // Safely access winrate_num with a fallback value
                const winrateNum = params.row?.winrate_num ?? 0;
                let color = theme.palette.text.primary;
                if (winrateNum >= 60) color = theme.palette.success.main;
                else if (winrateNum < 40) color = theme.palette.error.main;
                
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Typography sx={{ fontWeight: 'bold', color }}>
                            {params.row?.winrate || "0%"}
                        </Typography>
                    </Box>
                );
            }
        },
        { 
            field: 'streak', 
            headerName: 'Streak', 
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const streak = params.value;
                const color = streak > 0 ? 'success.main' : streak < 0 ? 'error.main' : 'text.primary';
                const icon = streak > 0 ? <TrendingUpIcon fontSize="small" /> : streak < 0 ? <TrendingDownIcon fontSize="small" /> : null;
                
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', color }}>
                            {icon}
                            <Typography sx={{ ml: 0.5, fontWeight: 'bold' }}>
                                {Math.abs(streak)}
                            </Typography>
                        </Box>
                    </Box>
                );
            }
        },
        {
            field: 'recent_change',
            headerName: '+/-',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const value = params.value;
                const color = value > 0 ? 'success.main' : value < 0 ? 'error.main' : 'text.primary';
                const formattedValue = value > 0 ? `+${value}` : value;
                const icon = value > 0 ? <TrendingUpIcon fontSize="small" /> : value < 0 ? <TrendingDownIcon fontSize="small" /> : null;
                
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', color }}>
                            {icon}
                            <Typography sx={{ ml: 0.5, fontWeight: 'bold' }}>
                                {formattedValue}
                            </Typography>
                        </Box>
                    </Box>
                );
            }
        },
    ];

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh', 
            pb: isMobile ? '120px' : '20px', // Increased padding for mobile to prevent cutoff
            bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.background.paper, 0.8) : alpha(theme.palette.background.paper, 0.2),
        }}>
            {/* Header with gradient */}
            <Box 
                component={motion.div}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                sx={{
                    textAlign: 'center',
                    mb: 5,
                    pt: '2em'
                }}>
            <Typography
                variant="h2"
                component="h1"
                fontWeight="bold"
                sx={{
                    mb: 2,
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '0 2px 10px rgba(0,0,0,0.1)',
                }}
            >
                OCE 4 Mans Leaderboard
            </Typography>
            </Box>
            
            {/* Main Content Box */}
            <Box sx={{ 
                flex: 1, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'flex-start',
                width: '100%',
                px: { xs: 1, md: 4 },
                mb: { xs: 4, md: 2 }, // Add margin at the bottom to prevent cutoff
            }}>
                <Paper elevation={3} sx={{ 
                    width: '100%',
                    maxWidth: 1200,
                    mx: 'auto',
                    borderRadius: 2,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    // Fixed height to ensure pagination controls are visible
                    height: isMobile ? 'calc(100vh - 280px)' : 'calc(100vh - 180px)',
                    minHeight: 400, // Minimum height to prevent extreme squishing
                }}>
                    <Box sx={{
                        p: 2,
                        bgcolor: theme.palette.mode === 'dark' ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.primary.main, 0.05),
                    }}>
                        <Tabs
                            value={selectedRank}
                            onChange={handleTabChange}
                            aria-label="rank tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{
                                '.MuiTabs-indicator': {
                                    backgroundColor: getRankColor(selectedRank),
                                    height: 3,
                                },
                                '.MuiTab-root': {
                                    fontWeight: 'bold',
                                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                                },
                                '.Mui-selected': {
                                    color: `${getRankColor(selectedRank)} !important`,
                                }
                            }}
                        >
                            {ranks.map((rank, index) => (
                                <Tab 
                                    key={index} 
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {rank === 'GLOBAL' ? 'Global' : `Rank ${rank}`}
                                            {rank === selectedRank && (
                                                <Chip 
                                                    size="small" 
                                                    label={leaderboardData[rank]?.length || 0} 
                                                    sx={{ 
                                                        ml: 1, 
                                                        height: 20, 
                                                        '& .MuiChip-label': { px: 1, fontSize: '0.7rem' },
                                                        bgcolor: alpha(getRankColor(rank), 0.2),
                                                        color: getRankColor(rank),
                                                    }} 
                                                />
                                            )}
                                        </Box>
                                    } 
                                    value={rank} 
                                />
                            ))}
                        </Tabs>
                    </Box>
                    
                    <Divider />
                    
                    <Box sx={{ 
                        flex: 1,
                        width: '100%',
                        height: '100%', // Take up remaining space
                        overflow: 'hidden', // Prevent double scrollbars
                    }}>
                        {loading ? (
                            <Box sx={{ p: 3 }}>
                                <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 2, borderRadius: 1 }} />
                                {[...Array(8)].map((_, idx) => (
                                    <Skeleton 
                                        key={idx} 
                                        variant="rectangular" 
                                        width="100%" 
                                        height={40} 
                                        sx={{ 
                                            mb: 1, 
                                            borderRadius: 1,
                                            opacity: 1 - (idx * 0.1)
                                        }} 
                                    />
                                ))}
                            </Box>
                        ) : (
                            selectedRank &&
                            leaderboardData[selectedRank] && (
                                <DataGrid
                                    rows={leaderboardData[selectedRank]}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    density={isMobile ? "compact" : "standard"}
                                    getRowId={(row) => row.place + (row.username || '')}
                                    disableSelectionOnClick
                                    components={{ Toolbar: CustomToolbar }}
                                    sx={{
                                        border: 'none',
                                        width: '100%',
                                        height: '100%',
                                        '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: theme.palette.mode === 'dark' 
                                                ? alpha(theme.palette.background.paper, 0.5) 
                                                : alpha(theme.palette.grey[100], 0.8),
                                            fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.9rem' },
                                        },
                                        '& .MuiDataGrid-cell': {
                                            fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.9rem' },
                                            py: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        },
                                        '& .MuiDataGrid-columnHeader': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        },
                                        '& .MuiDataGrid-row:nth-of-type(even)': {
                                            backgroundColor: theme.palette.mode === 'dark' 
                                                ? alpha(theme.palette.background.default, 0.3) 
                                                : alpha(theme.palette.background.default, 0.5),
                                        },
                                        '& .MuiDataGrid-row:hover': {
                                            backgroundColor: theme.palette.mode === 'dark' 
                                                ? alpha(theme.palette.primary.main, 0.1) 
                                                : alpha(theme.palette.primary.main, 0.05),
                                        },
                                        '& .MuiDataGrid-footerContainer': {
                                            borderTop: `1px solid ${theme.palette.divider}`,
                                            // Make sure footer is visible
                                            position: 'relative',
                                            zIndex: 2,
                                        },
                                        '& .MuiTablePagination-root': {
                                            fontSize: { xs: '0.75rem', md: '0.875rem' },
                                        },
                                    }}
                                />
                            )
                        )}
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

export default LeaderboardPage;