import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Grid2,
  IconButton,
  Container,
  Paper,
  Divider,
  Fade,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { YouTube, Twitter, Instagram } from "@mui/icons-material";
import { FaTwitch as Twitch } from "react-icons/fa";
import axios from "axios";

const StaffCard = ({ name, position, phrase, youtube, twitter, instagram, twitch, avatar_url }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const roleColors = {
    Owner: "#ff9800",
    "Chief of Operations": "#2196f3",
    "Upper Staff": "#4caf50",
    Moderator: "#9c27b0",
  };

  const borderColor = roleColors[position] || "#757575";

  return (
    <Fade in timeout={800}>
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "flex-start",
          p: 3,
          pb: 2,
          borderRadius: 2,
          height: "100%",
          position: "relative",
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: 8,
          },
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "4px",
            backgroundColor: borderColor,
          }
        }}
      >
        {/* Avatar Section */}
        <Avatar
          src={avatar_url}
          alt={name}
          sx={{
            width: 90,
            height: 90,
            mb: isMobile ? 2 : 0,
            mr: isMobile ? 0 : 3,
            border: `3px solid ${borderColor}`,
            boxShadow: `0 0 10px rgba(0,0,0,0.1)`,
          }}
        />

        <Box sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "flex-start",
          textAlign: isMobile ? "center" : "left"
        }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 0.5
            }}
          >
            {name}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              backgroundColor: borderColor,
              color: "#fff",
              px: 1.5,
              py: 0.5,
              borderRadius: 1,
              fontSize: "0.8rem",
              mb: 2
            }}
          >
            {position}
          </Typography>

          {phrase && (
            <Typography
              variant="body2"
              sx={{
                fontStyle: "italic",
                mb: 2,
                color: theme.palette.text.secondary,
                borderLeft: `3px solid ${theme.palette.divider}`,
                pl: 1.5,
                py: 0.5
              }}
            >
              "{phrase}"
            </Typography>
          )}

          {(youtube || twitter || instagram || twitch) && (
            <Box sx={{
              mt: 2,
              width: "100%",
            }}>
              <Divider sx={{ width: "100%", mb: 2 }} />
              <Box sx={{
                display: "flex",
                gap: 1,
                justifyContent: isMobile ? "center" : "flex-start"
              }}>
                {youtube && (
                  <IconButton
                    href={`https://www.youtube.com/${youtube}`}
                    target="_blank"
                    size="small"
                    sx={{
                      color: "#FF0000",
                      backgroundColor: "rgba(255, 0, 0, 0.1)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 0, 0, 0.2)",
                      }
                    }}
                  >
                    <YouTube fontSize="small" />
                  </IconButton>
                )}
                {twitter && (
                  <IconButton
                    href={`https://twitter.com/${twitter}`}
                    target="_blank"
                    size="small"
                    sx={{
                      color: "#1DA1F2",
                      backgroundColor: "rgba(29, 161, 242, 0.1)",
                      "&:hover": {
                        backgroundColor: "rgba(29, 161, 242, 0.2)",
                      }
                    }}
                  >
                    <Twitter fontSize="small" />
                  </IconButton>
                )}
                {instagram && (
                  <IconButton
                    href={`https://www.instagram.com/${instagram}`}
                    target="_blank"
                    size="small"
                    sx={{
                      color: "#E1306C",
                      backgroundColor: "rgba(225, 48, 108, 0.1)",
                      "&:hover": {
                        backgroundColor: "rgba(225, 48, 108, 0.2)",
                      }
                    }}
                  >
                    <Instagram fontSize="small" />
                  </IconButton>
                )}
                {twitch && (
                  <IconButton
                    href={`https://www.twitch.tv/${twitch}`}
                    target="_blank"
                    size="small"
                    sx={{
                      color: "#9146FF",
                      backgroundColor: "rgba(145, 70, 255, 0.1)",
                      "&:hover": {
                        backgroundColor: "rgba(145, 70, 255, 0.2)",
                      }
                    }}
                  >
                    <Twitch fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Fade>
  );
};

const StaffPage = () => {
  const [staffData, setStaffData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    axios.get("/api/staff")
      .then(response => {
        setStaffData(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError("Failed to load staff data");
        setLoading(false);
      });
  }, []);

  if (loading) return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "60vh"
    }}>
      <CircularProgress color="primary" />
    </Box>
  );

  if (error) return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "60vh"
    }}>
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    </Box>
  );

  return (
    <Container maxWidth="xl" sx={{ py: 6 }}>
      <Typography
        variant="h3"
        sx={{
          mb: 5,
          textAlign: "center",
          fontWeight: 700,
          position: "relative",
          "&:after": {
            content: '""',
            display: "block",
            width: "60px",
            height: "4px",
            backgroundColor: theme.palette.primary.main,
            margin: "12px auto",
            borderRadius: "2px"
          }
        }}
      >
        Meet Our Team
      </Typography>

      {Object.entries(staffData).map(([role, members], index) => (
        <Box key={role} sx={{ mb: 7 }}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              mb: 4,
              fontWeight: 600,
              color: theme.palette.text.primary,
              position: "relative",
              display: "inline-block",
              mx: "auto",
              width: "100%"
            }}
          >
            {role.replace(/([A-Z])/g, " $1").trim()}
          </Typography>

          <Grid2
            container
            spacing={4}
            justifyContent="center"
          >
            {members.map((member, i) => (
              <Grid2
                item
                key={member.discordId || i}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                sx={{ display: "flex" }}
              >
                <Box sx={{ width: "100%" }}>
                  <StaffCard {...member} />
                </Box>
              </Grid2>
            ))}
          </Grid2>

          {index < Object.entries(staffData).length - 1 && (
            <Divider sx={{ mt: 7, opacity: 0.7 }} />
          )}
        </Box>
      ))}
    </Container>
  );
};

export default StaffPage;