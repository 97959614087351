import { 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Typography, 
  Box, 
  Card,
  Alert,
  Divider,
  Fade,
  Zoom,
  Grow,
  Paper,
  IconButton,
  CircularProgress,
  Avatar,
  Tooltip,
  Chip,
  useTheme,
  styled,
  alpha
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import { 
  FaUserEdit, 
  FaTrash, 
  FaCheck, 
  FaTimes, 
  FaLink, 
  FaUnlink, 
  FaRedo, 
  FaInfoCircle, 
  FaShieldAlt 
} from "react-icons/fa";
import { motion } from "framer-motion";

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

// Custom styled components
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  borderRadius: theme.shape.borderRadius * 2,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  overflow: 'visible',
  position: 'relative',
  color: '#fff', // Make text white
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 40px rgba(0, 0, 0, 0.3)',
  },
}));

const StyledButton = styled(Button)(({ theme, color = 'primary' }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: '10px 24px',
  fontWeight: 600,
  boxShadow: '0 4px 14px rgba(0, 0, 0, 0.25)',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  textTransform: 'none',
  color: '#fff', // Make text white
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
    '&::after': {
      opacity: 1,
    },
  },
  '&:active': {
    transform: 'translateY(1px)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 1.5,
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(5px)',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputBase-input': {
    color: '#fff',
  },
  '& .MuiFormHelperText-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& svg': {
    fontSize: 24,
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
}));

const RemainingCountChip = styled(Chip)(({ theme, count, maxCount }) => {
  const percentage = count / maxCount;
  let color;
  
  if (percentage <= 0.33) color = theme.palette.error.main;
  else if (percentage <= 0.66) color = theme.palette.warning.main;
  else color = theme.palette.success.main;
  
  return {
    backgroundColor: alpha(color, 0.2),
    color: color,
    border: `1px solid ${color}`,
    fontWeight: 'bold',
    '& .MuiChip-icon': {
      color: color,
    },
  };
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  // Remove explicit background gradient to allow theme to affect it
  backdropFilter: 'blur(5px)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  padding: theme.spacing(2, 3),
  fontWeight: 600,
}));

// Allow dialog content to be affected by theme
const StyledDialogContent = DialogContent;

const StyledPaper = styled(Paper)(({ theme, variant = 'default' }) => {
  let bgColor = 'rgba(255, 255, 255, 0.05)';
  let borderColor = 'rgba(255, 255, 255, 0.1)';
  
  if (variant === 'info') {
    bgColor = 'rgba(25, 118, 210, 0.1)';
    borderColor = 'rgba(25, 118, 210, 0.2)';
  } else if (variant === 'success') {
    bgColor = 'rgba(76, 175, 80, 0.1)';
    borderColor = 'rgba(76, 175, 80, 0.2)';
  } else if (variant === 'warning') {
    bgColor = 'rgba(255, 152, 0, 0.1)';
    borderColor = 'rgba(255, 152, 0, 0.2)';
  } else if (variant === 'error') {
    bgColor = 'rgba(244, 67, 54, 0.1)';
    borderColor = 'rgba(244, 67, 54, 0.2)';
  } else if (variant === 'secondary') {
    bgColor = 'rgba(156, 39, 176, 0.1)';
    borderColor = 'rgba(156, 39, 176, 0.2)';
  }
  
  return {
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    borderRadius: theme.shape.borderRadius * 2,
    color: '#fff', // Make text white
  };
});

// Custom styled alerts that follow theme in dialogs
const StyledAlert = styled(Alert)(({ theme, severity }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  '& .MuiAlert-icon': {
    color: severity === 'error' ? theme.palette.error.main : 
           severity === 'warning' ? theme.palette.warning.main :
           severity === 'info' ? theme.palette.info.main :
           theme.palette.success.main,
  },
}));

// Custom Typography with white text
const WhiteTypography = styled(Typography)({
  color: '#fff', // Make text white
});

function isValidUsername(username) {
  const pattern = /^(?!.*[_.-]{2})(?![_.-])[a-zA-Z0-9_.-]{3,16}(?<![_.-])$/;
  return pattern.test(username);
}

function SettingsTab({ profile_name, name_changes, rank_checks, epicAccount }) {
  const theme = useTheme();
  const [newUsername, setNewUsername] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [usernameChangeCount, setUsernameChangeCount] = useState(name_changes);
  const [rankCheckCount, setRankCheckCount] = useState(rank_checks);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteUsername, setDeleteUsername] = useState("");
  const [error, setError] = useState(null);
  const { loggedIn, userData, setLoggedIn, setUserData, isLoading } = useAuth();
  const [success, setSuccess] = useState(null);
  const [rankConfirmOpen, setRankConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setFadeIn(true);
    
    if (location.state?.successMessage) {
      setSuccess(location.state.successMessage);
      navigate(location.pathname, { replace: true, state: {} });
    }

    // Parse query parameters from the hash fragment
    const hash = location.hash.substring(1);
    const queryParams = new URLSearchParams(hash.split('?')[1]);
    const status = queryParams.get("status");
    const message = queryParams.get("message");

    if (status && message) {
      if (status === "success") {
        setSuccess(decodeURIComponent(message));
      } else if (status === "error") {
        setError(decodeURIComponent(message));
      }
    }
  }, [location, navigate]);

  const clearMessages = () => {
    if (error) setTimeout(() => setError(null), 5000);
    if (success) setTimeout(() => setSuccess(null), 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [error, success]);

  const handleRankCheck = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${apiUrl}/api/rank-check`);
      setRankCheckCount(prev => prev - 1);
      setSuccess(`Rank recalculated successfully! You have been placed in ${res.data.new_rank}!`);
      setRankConfirmOpen(false);
    } catch (err) {
      const errorMessage = err.response?.data?.message || "An unexpected error occurred";
      setError(errorMessage);
    } finally {
      setLoading(false);
      setRankConfirmOpen(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (deleteUsername !== profile_name) {
      setError("Username does not match. Please type your username correctly.");
      return;
    }
    
    setLoading(true);
    try {
      await axios.delete(`${apiUrl}/api/profiles`, { withCredentials: true });
      await axios.post(`${apiUrl}/api/auth/discord/logout`, {}, { withCredentials: true });
      setSuccess("Account deleted successfully.");
      setUserData(null);
      setLoggedIn(false);
      setTimeout(() => navigate("/"), 1500);
    } catch (err) {
      setError(err.response?.data?.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
      setDeleteConfirmOpen(false);
    }
  };

  const handleUsernameChange = async () => {
    if (usernameChangeCount <= 0) {
      setError("You have reached the limit of 3 username changes.");
      return;
    }
    
    if (!isValidUsername(newUsername)) {
      setError("This username is not valid! Usernames must be between 3 and 16 characters long. They can only contain letters (A-Z, a-z), numbers (0-9), underscores (_), hyphens (-), and dots (.). Usernames cannot start or end with a dot (.) or a hyphen (-) and cannot contain consecutive dots (..), hyphens (--), or mixed sequences like (.- or -.)");
      setConfirmOpen(false);
      return;
    }
    
    setLoading(true);
    try {
      await axios.post(`${apiUrl}/api/change-username`, { new_username: newUsername });
      setUsernameChangeCount(prev => prev - 1);
      setSuccess("Username changed successfully!");
      setUserData({
        userId: userData.userid,
        profileName: newUsername,
        avatarUrl: userData.avatarUrl
      });
      setTimeout(() => {
        navigate(`/profiles/${newUsername}`, { state: { successMessage: "Username changed successfully!" } });
      }, 1500);
    } catch (err) {
      const errorMessage = err.response?.data?.message || "An unexpected error occurred";
      setError(errorMessage);
    } finally {
      setLoading(false);
      setConfirmOpen(false);
    }
  };

  const handleLinkEpicAccount = () => {
    window.location.href = `${apiUrl}/api/auth/epic/login`;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}>
        {/* Notifications */}
        {(error || success) && (
          <Box sx={{ position: 'relative' }}>
            {error && (
              <Fade in={!!error} timeout={600}>
                <StyledAlert 
                  severity="error" 
                  variant="filled"
                  sx={{ 
                    borderRadius: 2,
                    boxShadow: '0 4px 12px rgba(211, 47, 47, 0.3)',
                    marginBottom: 2
                  }}
                  action={
                    <IconButton color="inherit" size="small" onClick={() => setError(null)}>
                      <FaTimes />
                    </IconButton>
                  }
                >
                  {error}
                </StyledAlert>
              </Fade>
            )}
            
            {success && (
              <Fade in={!!success} timeout={600}>
                <StyledAlert 
                  severity="success" 
                  variant="filled"
                  sx={{ 
                    borderRadius: 2,
                    boxShadow: '0 4px 12px rgba(76, 175, 80, 0.3)'
                  }}
                  action={
                    <IconButton color="inherit" size="small" onClick={() => setSuccess(null)}>
                      <FaTimes />
                    </IconButton>
                  }
                >
                  {success}
                </StyledAlert>
              </Fade>
            )}
          </Box>
        )}
        
        {/* Settings Cards Grid */}
        <Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)" }, gap: 4 }}>
          {/* Rank Check Panel */}
          <Grow in={fadeIn} timeout={800} style={{ transformOrigin: '0 0 0' }}>
            <GlassCard sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardHeader>
                <FaShieldAlt />
                <WhiteTypography variant="h6" fontWeight={600}>Rank Check</WhiteTypography>
              </CardHeader>
              
              <Box sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 2 
              }}>
                <Box sx={{ width: '100%', mb: 3 }}>
                  <StyledPaper 
                    variant="info"
                    sx={{ p: 2, mb: 3 }}
                  >
                    <WhiteTypography variant="body2">
                      Checking your rank will recalculate your MMR based on your recent performance.
                      This can result in promotion or demotion.
                    </WhiteTypography>
                  </StyledPaper>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <RemainingCountChip
                      icon={<FaRedo />}
                      label={`${rankCheckCount} of 2 remaining this month`}
                      count={rankCheckCount}
                      maxCount={2}
                    />
                  </Box>
                </Box>
                
                <StyledButton
                  variant="contained"
                  color="primary"
                  startIcon={<FaShieldAlt />}
                  onClick={() => setRankConfirmOpen(true)}
                  disabled={rankCheckCount <= 0 || loading}
                  fullWidth
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Check My Rank"}
                </StyledButton>
              </Box>
            </GlassCard>
          </Grow>
          
          {/* Username Change Panel */}
          <Grow in={fadeIn} timeout={1000} style={{ transformOrigin: '0 0 0' }}>
            <GlassCard sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardHeader>
                <FaUserEdit />
                <WhiteTypography variant="h6" fontWeight={600}>Change Username</WhiteTypography>
              </CardHeader>
              
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                  <StyledTextField
                    label="New Username"
                    variant="outlined"
                    fullWidth
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                      endAdornment: newUsername && isValidUsername(newUsername) ? (
                        <Tooltip title="Valid username format">
                          <Box component="span" sx={{ color: 'success.main' }}>
                            <FaCheck />
                          </Box>
                        </Tooltip>
                      ) : newUsername ? (
                        <Tooltip title="Invalid username format">
                          <Box component="span" sx={{ color: 'error.main' }}>
                            <FaTimes />
                          </Box>
                        </Tooltip>
                      ) : null
                    }}
                  />
                  
                  <StyledPaper sx={{ p: 2, mb: 2 }}>
                    <WhiteTypography variant="body2" sx={{ fontSize: '0.85rem' }}>
                      Usernames must be 3-16 characters and can contain letters, numbers, underscores, 
                      hyphens, and dots. Cannot start/end with dots or hyphens.
                    </WhiteTypography>
                  </StyledPaper>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <RemainingCountChip
                      icon={<FaUserEdit />}
                      label={`${usernameChangeCount} of 3 changes remaining`}
                      count={usernameChangeCount}
                      maxCount={3}
                    />
                  </Box>
                </Box>
                
                <StyledButton
                  variant="contained"
                  color="secondary"
                  startIcon={<FaUserEdit />}
                  onClick={() => setConfirmOpen(true)}
                  disabled={usernameChangeCount <= 0 || !newUsername || !isValidUsername(newUsername) || loading}
                  fullWidth
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Change Username"}
                </StyledButton>
              </Box>
            </GlassCard>
          </Grow>

          {/* Epic Games Account */}
          <Grow in={fadeIn} timeout={1200} style={{ transformOrigin: '0 0 0' }}>
            <GlassCard sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardHeader>
                <img src="/epic_games.svg" alt="Epic Games" width={24} height={24} style={{ marginRight: '12px' }} />
                <WhiteTypography variant="h6" fontWeight={600}>Epic Games Account</WhiteTypography>
              </CardHeader>
              
              <Box sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 2 
              }}>
                {epicAccount && Object.keys(epicAccount).length > 0 ? (
                  <>
                    <StyledPaper 
                      variant="success"
                      sx={{ 
                        p: 3, 
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3
                      }}
                    >
                      <Box sx={{ position: 'relative' }}>
                        <Avatar 
                          src="/epic_games.svg" 
                          alt="Epic Games" 
                          sx={{ 
                            width: 60, 
                            height: 60,
                            p: 1.5,
                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                            border: '2px solid rgba(255, 255, 255, 0.3)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)'
                          }} 
                        />
                        <Box 
                          sx={{ 
                            position: 'absolute', 
                            bottom: -2, 
                            right: -2, 
                            bgcolor: 'success.main',
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            border: '2px solid rgba(0, 0, 0, 0.8)',
                          }} 
                        />
                      </Box>
                      
                      <Box sx={{ textAlign: 'center' }}>
                        <WhiteTypography variant="subtitle1" fontWeight={600}>
                          {epicAccount.epic_name}
                        </WhiteTypography>
                        <Tooltip title={epicAccount.epic_id}>
                          <WhiteTypography variant="body2" sx={{ fontSize: '0.85rem' }} >
                            Account ID: {epicAccount.epic_id.substring(0, 8)}...
                          </WhiteTypography>
                        </Tooltip>
                      </Box>
                    </StyledPaper>
                    
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      startIcon={<FaLink />}
                      onClick={handleLinkEpicAccount}
                      disabled={loading}
                      fullWidth
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : "Relink Account"}
                    </StyledButton>
                  </>
                ) : (
                  <>
                    <StyledPaper 
                      variant="error"
                      sx={{ 
                        p: 3, 
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3
                      }}
                    >
                      <Box sx={{ 
                        bgcolor: 'rgba(0, 0, 0, 0.3)', 
                        borderRadius: '50%', 
                        p: 2,
                        border: '2px solid rgba(255, 255, 255, 0.1)'
                      }}>
                        <img src="/epic_games.svg" alt="Epic Games" width={32} height={32} />
                      </Box>
                      
                      <WhiteTypography variant="body2" textAlign="center">
                        You need to link your Epic Games account to track your stats and participate in ranked matches.
                      </WhiteTypography>
                    </StyledPaper>
                    
                    <StyledButton
                      variant="contained"
                      color="primary"
                      startIcon={<FaLink />}
                      onClick={handleLinkEpicAccount}
                      disabled={loading}
                      fullWidth
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : "Link Epic Games Account"}
                    </StyledButton>
                  </>
                )}
              </Box>
            </GlassCard>
          </Grow>

          {/* Delete Account Panel */}
          <Grow in={fadeIn} timeout={1400} style={{ transformOrigin: '0 0 0' }}>
            <GlassCard sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardHeader>
                <FaTrash />
                <WhiteTypography variant="h6" fontWeight={600}>Delete Account</WhiteTypography>
              </CardHeader>
              
              <Box sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                py: 2 
              }}>
                <StyledPaper 
                  variant="error"
                  sx={{ 
                    p: 3, 
                    width: '100%',
                    mb: 3
                  }}
                >
                  <WhiteTypography variant="subtitle2" color="error" sx={{ fontWeight: 600, mb: 1 }}>
                    Warning: This action cannot be undone!
                  </WhiteTypography>
                  <WhiteTypography variant="body2">
                    Deleting your account will permanently remove all your data, including rank history, 
                    statistics, and profile information.
                  </WhiteTypography>
                </StyledPaper>
                
                <StyledButton
                  variant="contained"
                  color="error"
                  startIcon={<FaTrash />}
                  onClick={() => setDeleteConfirmOpen(true)}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Delete Account"}
                </StyledButton>
              </Box>
            </GlassCard>
          </Grow>
        </Box>
        
        {/* Dialogs */}
        <Dialog 
          open={deleteConfirmOpen} 
          onClose={() => setDeleteConfirmOpen(false)}
          PaperProps={{
            sx: {
              // Remove explicit background color to use theme background
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
              borderRadius: 3,
              border: '1px solid rgba(255, 255, 255, 0.1)',
            }
          }}
        >
          <StyledDialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FaTrash style={{ marginRight: 8, color: theme.palette.error.main }} />
              Confirm Account Deletion
            </Box>
          </StyledDialogTitle>
          
          <StyledDialogContent>
            <Typography sx={{ mb: 3, pt: "2em" }}>
              This action is irreversible. Your account and all associated data will be permanently deleted.
            </Typography>
            
            <StyledAlert severity="warning" sx={{ mb: 3 }}>
              To confirm, please type your username: <strong>{profile_name}</strong>
            </StyledAlert>
            
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={deleteUsername}
              onChange={(e) => setDeleteUsername(e.target.value)}
              error={deleteUsername !== "" && deleteUsername !== profile_name}
              helperText={deleteUsername !== "" && deleteUsername !== profile_name ? "Usernames don't match" : ""}
            />
          </StyledDialogContent>
          
          <DialogActions sx={{ p: 3 }}>
            <Button 
              variant="outlined" 
              onClick={() => setDeleteConfirmOpen(false)}
              disabled={loading}
              startIcon={<FaTimes />}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              color="error" 
              onClick={handleDeleteAccount}
              disabled={deleteUsername !== profile_name || loading}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <FaTrash />}
            >
              Delete Forever
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog 
          open={rankConfirmOpen} 
          onClose={() => setRankConfirmOpen(false)}
          PaperProps={{
            sx: {
              // Remove explicit background color to use theme background
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
              borderRadius: 3,
              border: '1px solid rgba(255, 255, 255, 0.1)',
            }
          }}
        >
          <StyledDialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FaShieldAlt style={{ marginRight: 8, color: theme.palette.primary.main }} />
              Confirm Rank Check
            </Box>
          </StyledDialogTitle>
          
          <StyledDialogContent>
            <Typography paragraph sx={{pt: '2em'}}>
              Are you sure you want to perform a rank check? This process cannot be undone and may affect your current rank.
            </Typography>
            
            <StyledAlert severity="info" sx={{ mb: 2 }}>
              You currently have <strong>{rankCheckCount}</strong> rank check{rankCheckCount !== 1 && 's'} remaining this month.
            </StyledAlert>
            
            <Typography variant="body2" color="text.secondary">
              Your MMR will be recalculated based on your recent performance, which could result in promotion or demotion.
            </Typography>
          </StyledDialogContent>
          
          <DialogActions sx={{ p: 3 }}>
            <Button 
              variant="outlined" 
              onClick={() => setRankConfirmOpen(false)}
              disabled={loading}
              startIcon={<FaTimes />}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleRankCheck}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <FaCheck />}
            >
              Check Rank
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog 
          open={confirmOpen} 
          onClose={() => setConfirmOpen(false)}
          PaperProps={{
            sx: {
              // Remove explicit background color to use theme background
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
              borderRadius: 3,
              border: '1px solid rgba(255, 255, 255, 0.1)',
            }
          }}
        >
          <StyledDialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FaUserEdit style={{ marginRight: 8, color: theme.palette.secondary.main }} />
              Confirm Username Change
            </Box>
          </StyledDialogTitle>
          
          <StyledDialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 1 }}>
              <Typography variant="body1" sx={{pt: '2em'}}>
                Are you sure you want to change your username to:
              </Typography>
              
              <Paper 
                elevation={0}
                sx={{ 
                  p: 2, 
                  textAlign: 'center',
                  bgcolor: alpha(theme.palette.secondary.main, 0.1),
                  border: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
                  borderRadius: theme.shape.borderRadius * 2,
                }}
              >
                <Typography variant="h6" color="secondary" fontWeight={600}>
                  {newUsername}
                </Typography>
              </Paper>
              
              <StyledAlert severity="info">
                You have <strong>{usernameChangeCount}</strong> username {usernameChangeCount === 1 ? 'change' : 'changes'} remaining.
              </StyledAlert>

              <Typography variant="body2" color="text.secondary">
                After changing your username, you'll be redirected to your profile page with the new username.
              </Typography>
            </Box>
          </StyledDialogContent>
          
          <DialogActions sx={{ p: 3 }}>
            <Button 
              variant="outlined" 
              onClick={() => setConfirmOpen(false)}
              disabled={loading}
              startIcon={<FaTimes />}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleUsernameChange}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <FaCheck />}
            >
              Confirm Change
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </motion.div>
  );
}

export default SettingsTab;