import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Grid2, 
  Avatar, 
  Box, 
  Typography, 
  LinearProgress, 
  Skeleton, 
  Tab, 
  Tabs, 
  Button,
  Paper,
  Chip,
  Divider,
  Badge,
  Fade,
  Zoom,
  Grow,
  CircularProgress,
  useTheme,
  styled
} from "@mui/material";
import { 
  FaDiscord, 
  FaGamepad, 
  FaTrophy, 
  FaChartLine, 
  FaHistory, 
  FaMedal,
  FaLink
} from "react-icons/fa";
import axios from 'axios';
import useAuth from './hooks/useAuth';
import SettingsTab from "./components/SettingsPage";
import { Alert } from '@mui/material';
import { motion } from 'framer-motion';

const apiUrl = process.env.REACT_APP_API_URL || "http://151.229.224.242";

const rankConfig = {
  D: { 
    color: "#ff4d4d", 
    gradient: "linear-gradient(135deg, #ff4d4d 0%, #ff6e6e 100%)",
    label: "Beginner",
    description: "Starting rank for new players"
  },
  C: { 
    color: "#ff944d", 
    gradient: "linear-gradient(135deg, #ff944d 0%, #ffb06e 100%)",
    label: "Apprentice",
    description: "Building fundamentals"
  },
  B: { 
    color: "#ffcc4d", 
    gradient: "linear-gradient(135deg, #ffcc4d 0%, #ffe06e 100%)",
    label: "Intermediate",
    description: "Consistent performer"
  },
  A: { 
    color: "#94ff4d", 
    gradient: "linear-gradient(135deg, #94ff4d 0%, #b4ff6e 100%)",
    label: "Advanced",
    description: "High-skilled player"
  },
  X: { 
    color: "#4dff4d", 
    gradient: "linear-gradient(135deg, #4dff4d 0%, #6eff6e 100%)",
    label: "Expert",
    description: "Elite competitor"
  },
  S: { 
    color: "#00cc00", 
    gradient: "linear-gradient(135deg, #00cc00 0%, #33ff33 100%)",
    label: "Master",
    description: "Top-tier player"
  }
};

// Custom styled components
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  borderRadius: theme.shape.borderRadius * 2,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  height: '100%', // Ensure consistent height
  display: 'flex',
  flexDirection: 'column',
  color: '#ffffff', // Default text color set to white
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 40px rgba(0, 0, 0, 0.3)',
  },
}));

const AnimatedAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: '4px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)',
  },
}));

const RankBadge = styled(Box)(({ theme, rank }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 24,
  background: rankConfig[rank]?.gradient || '#444444',
  boxShadow: `0 4px 12px ${rankConfig[rank]?.color || '#444444'}80`,
  border: '2px solid rgba(255, 255, 255, 0.3)',
  color: '#ffffff',
  textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.3)',
  backdropFilter: 'blur(10px)',
  borderRadius: 50,
  padding: '4px 8px',
  marginBottom: theme.spacing(4),
  '& .MuiTab-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    fontWeight: 500,
    minWidth: 100,
    borderRadius: 50,
    '&.Mui-selected': {
      color: '#ffffff',
      background: 'rgba(255, 255, 255, 0.1)',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const StatusChip = styled(Chip)(({ theme, color }) => ({
  fontWeight: 'bold',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  animation: 'pulse 2s infinite',
  '@keyframes pulse': {
    '0%': { boxShadow: `0 0 0 0 ${color}50` },
    '70%': { boxShadow: `0 0 0 6px ${color}00` },
    '100%': { boxShadow: `0 0 0 0 ${color}00` },
  },
}));

// Helper functions
function getColorForValue(value) {
  const clampedValue = Math.min(Math.max(value, 0), 100);
  let red, green;

  if (clampedValue < 50) {
    red = 255;
    green = Math.floor(clampedValue * 5.1);
  } else {
    red = Math.floor((100 - clampedValue) * 5.1);
    green = 255;
  }

  return `rgb(${red}, ${green}, 0)`;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

function ProfilePage({ profile_name }) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedIn, userData, isLoading } = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [rankData, setRankData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [epicInfo, setEpicInfo] = useState({});
  const [profileInfo, setProfileInfo] = useState({
    epic_name: '',
    epic_id: '',
    discord_name: '',
    mmr_rating: 0,
    rank: '',
    matches_won: 0,
    matches_lost: 0,
    avatar_url: '',
    created_at: '',
    match_history: [],
    streak: 0,
    streakFormatted: '',
    winRate: 0,
    streakColor: '',
    toDemote: '-',
    placement_matches: '',
    toPromote: '-',
    progressValue: 0,
    name_changes_left: 0,
    rank_checks_this_month: 0
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${apiUrl}/api/profile/${profile_name}`);
        setProfileData(res.data.player);
        setRankData(res.data.rank_info);
      } catch (err) {
        if (err.response) {
          if (err.response.status === 404) {
            if (loggedIn && userData?.profileName === profile_name) {
              window.location.href = `${apiUrl}/register`;
            }
            window.location.href = `${apiUrl}/404`;
          }
        } else if (err.request) {
          setError("No response from the server.");
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
        setTimeout(() => setFadeIn(true), 100);
      }
    };

    fetchData();
  }, [profile_name, loggedIn, userData]);

  useEffect(() => {
    if (!loading && profileData && rankData) {
      const { epic_id, epic_name, discord_name, mmr_rating, rank, matches_won, matches_lost, avatar_url, created_at, match_history, streak, name_changes_left, rank_checks_this_month } = profileData;
      const streakFormatted = streak >= 0 ? `+${streak}` : `${streak}`;
      const matches_played = matches_won + matches_lost;
      const winRate = matches_played <= 0 ? 0 : ((matches_won / matches_played) * 100).toFixed(1);
      const streakColor = streak >= 0 ? "green" : "red";
      const rankInfo = rankData[rank] || {};
      if (epic_id && epic_name) {
        setEpicInfo({ epic_id, epic_name });
      }
      const toPromote = rankInfo.promotion ? rankInfo.promotion - mmr_rating : "-";
      const toDemote = rankInfo.demotion ? mmr_rating - rankInfo.demotion : "-";
      let progressValue = 0;
      if (rankInfo.promotion && rankInfo.demotion && rankInfo.promotion !== rankInfo.demotion) {
        progressValue = ((mmr_rating - rankInfo.demotion) / (rankInfo.promotion - rankInfo.demotion)) * 100;
      }

      setProfileInfo({
        epic_name,
        epic_id,
        discord_name,
        mmr_rating,
        rank,
        matches_won,
        matches_lost,
        avatar_url,
        created_at,
        name_changes_left,
        match_history,
        streak,
        streakFormatted,
        winRate,
        rank_checks_this_month,
        streakColor,
        toPromote,
        toDemote,
        progressValue,
      });
    }
  }, [loading, profileData, rankData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const tabName = newValue === 0 ? 'overview' : 'settings';
    navigate(`/profiles/${userData?.profileName}#${tabName}`);
  };

  useEffect(() => {
    const hash = location.hash.substring(1); // Remove the leading '#'

    if (hash.startsWith('settings') && loggedIn && userData?.profileName === profile_name) {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
  }, [location.hash, loggedIn, userData?.profileName, profile_name]);

  // Render match history icons with MUI components
  const renderMatchHistory = (history) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
        {history.map((match, index) => {
          let color, label, icon;
          
          if (match === true) {
            color = "success";
            label = "W";
          } else if (match === false) {
            color = "error";
            label = "L";
          } else {
            color = "default";
            label = "-";
          }
          
          return (
            <Zoom in key={index} style={{ transitionDelay: `${index * 100}ms` }}>
              <Chip 
                label={label}
                color={color}
                size="small"
                sx={{ 
                  width: 36, 
                  height: 36, 
                  borderRadius: '50%',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                }}
              />
            </Zoom>
          );
        })}
      </Box>
    );
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage: "url('/background_rl_blur.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: '100vh',
      gap: 3,
      p: 3,
      transition: 'all 0.3s ease',
      color: '#ffffff', // Default text color set to white for the entire page
    }}>
      {/* Animated Tabs */}
      <Fade in timeout={800}>
        <StyledTabs value={tabValue} onChange={handleTabChange} centered variant="fullWidth">
          <Tab label="Profile Overview" icon={<FaGamepad />} iconPosition="start" />
          {loggedIn && userData?.profileName === profile_name && 
            <Tab label="Settings" icon={<FaLink />} iconPosition="start" />
          }
        </StyledTabs>
      </Fade>

      {tabValue === 0 && (
        <Fade in={fadeIn} timeout={800}>
          <Box sx={{ width: "100%", maxWidth: 1000, display: "flex", flexDirection: "column", gap: 4 }}>
            {/* Profile Header Card */}
            <GlassCard>
              <CardContent sx={{ p: 3, flexGrow: 1 }}>
                <Grid2 container spacing={3} alignItems="center">
                  <Grid2 item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {loading ? (
                      <Skeleton variant="circular" width={120} height={120} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                    ) : (
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          profileInfo.rank && (
                            <RankBadge rank={profileInfo.rank}>
                              {profileInfo.rank}
                            </RankBadge>
                          )
                        }
                      >
                        <AnimatedAvatar
                          src={profileInfo.avatar_url || "/default-avatar.jpg"}
                          alt={profile_name}
                        />
                      </Badge>
                    )}
                  </Grid2>

                  <Grid2 item xs={12} sm={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                      {loading ? (
                        <>
                          <Skeleton variant="text" width="60%" height={50} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                          <Skeleton variant="text" width="40%" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="h3"
                            sx={{
                              fontWeight: 700,
                              marginBottom: 1,
                              background: 'linear-gradient(45deg, #ffffff, #e0e0e0)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              textShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                            }}
                          >
                            {profile_name}
                          </Typography>
                          
                          <Typography variant="body1" sx={{ color: '#ffffff', mb: 2 }}>
                            Member since {formatDate(profileInfo.created_at)}
                          </Typography>

                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                            {profileInfo.epic_name && (
                              <Chip
                                icon={<img src="/epic_games.svg" alt="Epic Games" width={16} height={16} />}
                                label={profileInfo.epic_name}
                                variant="outlined"
                                sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', color: '#ffffff' }}
                              />
                            )}
                            {profileInfo.discord_name && (
                              <Chip
                                icon={<FaDiscord color="#7289DA" />}
                                label={profileInfo.discord_name}
                                variant="outlined"
                                sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', color: '#ffffff' }}
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid2>
                </Grid2>
              </CardContent>
            </GlassCard>

            {profileInfo.epic_name && profileInfo.epic_id ? (
              <Grid2 container spacing={3} sx={{ 
                display: 'grid',
                gridTemplateColumns: { 
                  xs: '1fr', 
                  sm: 'repeat(2, 1fr)', 
                  md: 'repeat(3, 1fr)' 
                },
                gap: 3,
                '& > .MuiGrid2-root': {
                  // Reset Grid2 padding since we're using display: grid
                  padding: 0,
                  width: '100%',
                  maxWidth: '100%'
                }
              }}>
                {/* Rank Card */}
                <Grid2>
                  <Grow in={fadeIn} timeout={1000} style={{ transformOrigin: '0 0 0' }}>
                    <GlassCard>
                      <CardContent sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <FaTrophy style={{ color: '#FFD700', marginRight: 8 }} />
                          <Typography variant="h6" sx={{ fontWeight: 600, color: '#ffffff' }}>Rank Details</Typography>
                        </Box>
                        
                        {loading ? (
                          <>
                            <Skeleton variant="rectangular" height={60} sx={{ borderRadius: 2, mb: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="text" width="80%" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="text" width="60%" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="rectangular" height={10} sx={{ borderRadius: 1, mt: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                          </>
                        ) : (
                          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Box>
                              <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                flexDirection: 'column',
                                mb: 3
                              }}>
                                <Typography 
                                  variant="h2" 
                                  sx={{ 
                                    fontWeight: 800,
                                    color: rankConfig[profileInfo.rank]?.color,
                                    textShadow: `0 0 10px ${rankConfig[profileInfo.rank]?.color}80`,
                                    mb: 1
                                  }}
                                >
                                  {profileInfo.rank}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500, color: '#ffffff' }}>
                                  {rankConfig[profileInfo.rank]?.label}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#ffffff', textAlign: 'center' }}>
                                  {rankConfig[profileInfo.rank]?.description}
                                </Typography>
                              </Box>
                              
                              <Box sx={{ textAlign: 'center', mb: 2 }}>
                                <Chip 
                                  label={`${profileInfo.mmr_rating} MMR`} 
                                  sx={{ 
                                    fontWeight: 'bold',
                                    background: rankConfig[profileInfo.rank]?.gradient || 'default',
                                    color: '#fff',
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                                    mb: 2
                                  }}
                                />
                              </Box>
                            </Box>
                            
                            <Box sx={{ mt: 'auto' }}>
                              <Box sx={{ mb: 1 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, display: 'flex', justifyContent: 'space-between', color: '#ffffff' }}>
                                  <span>Rank Progress</span>
                                  <span>{Math.round(profileInfo.progressValue)}%</span>
                                </Typography>
                                <LinearProgress 
                                  variant="determinate" 
                                  value={profileInfo.progressValue} 
                                  sx={{ 
                                    height: 8, 
                                    borderRadius: 1,
                                    background: 'rgba(255,255,255,0.1)',
                                    '& .MuiLinearProgress-bar': {
                                      background: rankConfig[profileInfo.rank]?.gradient
                                    }
                                  }}
                                />
                              </Box>
                              
                              <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography variant="body2" sx={{ textAlign: 'center', color: '#ffffff', fontSize: '0.8rem', mb: 0.5 }}>
                                  MMR THRESHOLDS
                                </Typography>
                                
                                {profileInfo.toPromote !== "-" && (
                                  <Paper 
                                    elevation={0} 
                                    sx={{ 
                                      p: 1.5, 
                                      bgcolor: 'rgba(76, 175, 80, 0.1)',
                                      border: '1px solid rgba(76, 175, 80, 0.2)',
                                      borderRadius: 2,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      color: '#ffffff'
                                    }}
                                  >
                                    <Typography variant="body2" sx={{ color: '#4CAF50', display: 'flex', alignItems: 'center' }}>
                                      <Box component="span" sx={{ mr: 1, fontSize: '1.2rem' }}>▲</Box>
                                      Promotion
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#4CAF50' }}>
                                      {profileInfo.toPromote} MMR
                                    </Typography>
                                  </Paper>
                                )}
                                
                                {profileInfo.toDemote !== "-" && (
                                  <Paper 
                                    elevation={0} 
                                    sx={{ 
                                      p: 1.5, 
                                      bgcolor: 'rgba(244, 67, 54, 0.1)',
                                      border: '1px solid rgba(244, 67, 54, 0.2)',
                                      borderRadius: 2,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      color: '#ffffff'
                                    }}
                                  >
                                    <Typography variant="body2" sx={{ color: '#F44336', display: 'flex', alignItems: 'center' }}>
                                      <Box component="span" sx={{ mr: 1, fontSize: '1.2rem' }}>▼</Box>
                                      Demotion
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#F44336' }}>
                                      {profileInfo.toDemote} MMR
                                    </Typography>
                                  </Paper>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </CardContent>
                    </GlassCard>
                  </Grow>
                </Grid2>

                {/* Statistics Card */}
                <Grid2>
                  <Grow in={fadeIn} timeout={1200} style={{ transformOrigin: '0 0 0' }}>
                    <GlassCard>
                      <CardContent sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <FaChartLine style={{ color: '#64B5F6', marginRight: 8 }} />
                          <Typography variant="h6" sx={{ fontWeight: 600, color: '#ffffff' }}>Statistics</Typography>
                        </Box>
                        
                        {loading ? (
                          <>
                            <Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="text" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="rectangular" height={40} sx={{ borderRadius: 2, mt: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                          </>
                        ) : (
                          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Box>
                              <Grid2 container spacing={2} sx={{ mb: 3 }}>
                                <Grid2 item xs={6}>
                                  <Paper 
                                    elevation={0} 
                                    sx={{ 
                                      p: 2, 
                                      textAlign: 'center',
                                      background: 'rgba(76, 175, 80, 0.1)',
                                      border: '1px solid rgba(76, 175, 80, 0.2)',
                                      borderRadius: 2,
                                      color: '#ffffff'
                                    }}
                                  >
                                    <Typography color="#4CAF50" variant="body2">Wins</Typography>
                                    <Typography variant="h4" sx={{ fontWeight: 700, color: '#ffffff' }}>{profileInfo.matches_won}</Typography>
                                  </Paper>
                                </Grid2>
                                <Grid2 item xs={6}>
                                  <Paper 
                                    elevation={0} 
                                    sx={{ 
                                      p: 2, 
                                      textAlign: 'center',
                                      background: 'rgba(244, 67, 54, 0.1)',
                                      border: '1px solid rgba(244, 67, 54, 0.2)',
                                      borderRadius: 2,
                                      color: '#ffffff'
                                    }}
                                  >
                                    <Typography color="#F44336" variant="body2">Losses</Typography>
                                    <Typography variant="h4" sx={{ fontWeight: 700, color: '#ffffff' }}>{profileInfo.matches_lost}</Typography>
                                  </Paper>
                                </Grid2>
                              </Grid2>
                              
                              <Divider sx={{ my: 2, opacity: 0.2 }} />
                              
                              <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, display: 'flex', justifyContent: 'space-between', color: '#ffffff' }}>
                                  <span>Win Rate</span>
                                  <span style={{ fontWeight: 'bold', color: getColorForValue(profileInfo.winRate) }}>
                                    {profileInfo.winRate}%
                                  </span>
                                </Typography>
                                <LinearProgress 
                                  variant="determinate" 
                                  value={parseFloat(profileInfo.winRate)} 
                                  sx={{ 
                                    height: 8, 
                                    borderRadius: 1,
                                    background: 'rgba(255,255,255,0.1)',
                                    '& .MuiLinearProgress-bar': {
                                      background: getColorForValue(profileInfo.winRate)
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                            
                            <Box sx={{ textAlign: 'center', mt: 'auto' }}>
                              <Typography variant="body2" sx={{ mb: 1, color: '#ffffff' }}>Current Streak</Typography>
                              <StatusChip 
                                label={profileInfo.streakFormatted} 
                                color={profileInfo.streakColor === "green" ? "#4CAF50" : "#F44336"}
                                sx={{ 
                                  fontWeight: 'bold',
                                  backgroundColor: profileInfo.streakColor === "green" ? "rgba(76, 175, 80, 0.2)" : "rgba(244, 67, 54, 0.2)",
                                  color: profileInfo.streakColor === "green" ? "#4CAF50" : "#F44336",
                                  border: `1px solid ${profileInfo.streakColor === "green" ? "#4CAF50" : "#F44336"}`,
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </CardContent>
                    </GlassCard>
                  </Grow>
                </Grid2>

                {/* Match History Card */}
                <Grid2>
                  <Grow in={fadeIn} timeout={1400} style={{ transformOrigin: '0 0 0' }}>
                    <GlassCard>
                      <CardContent sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <FaHistory style={{ color: '#FFB74D', marginRight: 8 }} />
                          <Typography variant="h6" sx={{ fontWeight: 600, color: '#ffffff' }}>Match History</Typography>
                        </Box>
                        
                        {loading ? (
                          <>
                            <Skeleton variant="rectangular" height={60} sx={{ borderRadius: 2, mb: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="text" width="70%" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                            <Skeleton variant="text" width="60%" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
                          </>
                        ) : (
                          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Box>
                              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                                {renderMatchHistory(profileInfo.match_history)}
                              </Box>
                              
                              <Divider sx={{ my: 2, opacity: 0.2 }} />
                              
                              <Grid2 container spacing={2} sx={{ mt: 2 }}>
                                <Grid2 item xs={6}>
                                  <Typography variant="body2" sx={{ mb: 1, textAlign: 'center', color: '#ffffff' }}>
                                    Total Matches
                                  </Typography>
                                  <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 700, color: '#ffffff' }}>
                                    {profileInfo.matches_won + profileInfo.matches_lost}
                                  </Typography>
                                </Grid2>
                                <Grid2 item xs={6}>
                                  <Typography variant="body2" sx={{ mb: 1, textAlign: 'center', color: '#ffffff' }}>
                                    Name Changes Left
                                  </Typography>
                                  <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 700, color: '#ffffff' }}>
                                    {profileInfo.name_changes_left}
                                  </Typography>
                                </Grid2>
                              </Grid2>
                            </Box>
                            
                            <Box sx={{ textAlign: 'center', mt: 'auto', pt: 2 }}>
                              <Chip 
                                icon={<FaMedal />} 
                                label={`${profileInfo.rank_checks_this_month}/2 Rank Checks This Month`} 
                                variant="outlined"
                                sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', color: '#ffffff' }}
                              />
                            </Box>
                          </Box>
                        )}
                      </CardContent>
                    </GlassCard>
                  </Grow>
                </Grid2>
              </Grid2>
            ) : (
              <Fade in={fadeIn} timeout={1000}>
                <GlassCard sx={{ p: 3, textAlign: 'center' }}>
                  <FaLink style={{ fontSize: 40, color: '#64B5F6', margin: '0 auto 16px' }} />
                  <Typography variant="h5" gutterBottom sx={{ color: '#ffffff' }}>Link your Epic Games account to start playing!</Typography>
                  <Typography variant="body1" sx={{ mb: 3, color: '#ffffff' }}>
                    Connect your Epic Games account to track your progress and compete in ranked matches.
                  </Typography>
                  
                  {loggedIn && userData?.profileName === profile_name && (
                    <Button 
                      variant="contained" 
                      color="primary" 
                      size="large"
                      startIcon={<FaLink />}
                      onClick={() => navigate(`/profiles/${profile_name}#settings`)}
                      sx={{ 
                        borderRadius: 8,
                        px: 4,
                        py: 1.5,
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                        background: 'linear-gradient(45deg, #3498db, #2980b9)',
                        color: '#ffffff',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #2980b9, #3498db)',
                          transform: 'translateY(-2px)',
                        }
                      }}
                    >
                      Go to Settings
                    </Button>
                  )}
                </GlassCard>
              </Fade>
            )}
          </Box>
        </Fade>
      )}

      {tabValue === 1 && loggedIn && userData?.profileName === profile_name && (
        <Fade in={fadeIn} timeout={800}>
          <Box sx={{ width: "100%", maxWidth: 700 }}>
            {/* Notification Messages */}
            <Fade in={!!success} timeout={500}>
              <Box sx={{ mb: 2, display: success ? 'block' : 'none' }}>
                <Alert 
                  severity="success"
                  sx={{ 
                    backdropFilter: 'blur(10px)',
                    background: 'rgba(76, 175, 80, 0.2)',
                    borderRadius: 2,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    color: '#ffffff'
                  }}
                >
                  {success}
                </Alert>
              </Box>
            </Fade>
            
            <Fade in={!!error} timeout={500}>
              <Box sx={{ mb: 2, display: error ? 'block' : 'none' }}>
                <Alert 
                  severity="error"
                  sx={{ 
                    backdropFilter: 'blur(10px)',
                    background: 'rgba(244, 67, 54, 0.2)',
                    borderRadius: 2,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    color: '#ffffff'
                  }}
                >
                  {error}
                </Alert>
              </Box>
            </Fade>
            
            {/* Settings Content */}
            <GlassCard>
              <CardContent sx={{ p: 3 }}>
                <SettingsTab 
                  profile_name={profile_name} 
                  name_changes={profileInfo.name_changes_left} 
                  rank_checks={profileInfo.rank_checks_this_month} 
                  epicAccount={epicInfo} 
                />
              </CardContent>
            </GlassCard>
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default ProfilePage;